<template>
  <div class="content" :class="{'scroll':plugins_data.cofig.showType==2}" :style="{'padding':`${plugins_data.style.padding_top}px ${plugins_data.style.padding_left}px ${plugins_data.style.padding_bottom}px `}">
    <div class="list" :style="{
            borderRadius:
              plugins_data.style.border_radius_top / 2 +'px ' +
              plugins_data.style.border_radius_top / 2 +'px ' +
              plugins_data.style.border_radius_bottom / 2 +'px ' +
              plugins_data.style.border_radius_bottom / 2 +'px',
              width: ((100/plugins_data.cofig.number)-4)+'%',
          }" v-for="(item,key) in plugins_data.menus" :key="key">
        <div class="list-img" >
            <!-- <img src="" alt=""> -->
            <el-image
              style="width: 100%; min-height: 80px"
              :src="item.images ||require('assets/icon/goods_col2.png')"
              fit="cover"
            ></el-image>
            <div class="topTitle" :style="{color:plugins_data.style.topTitleColor,'text-align':plugins_data.style.topTitleAlign}">
            {{item.topTitle}}</div>
        </div>
        <div class="bottomTitle" :style="{color:plugins_data.style.bottomTitleColor,'text-align':plugins_data.style.bottomTitleAlign}">
        {{item.bottomTitle}}
        </div> 
    </div>
  </div>
</template>

<script>
export default {
  name: "imageExhibition",
  data() {
    return {
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  watch: {
    plugins_data: {
      handler: function () {
        this.$nextTick(() => {
        });
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="less">
.scroll{
    white-space: nowrap;
    overflow: auto;
}
.content{
    .list{
        display: inline-block;
        overflow: hidden;
        margin: 0 2%;
        .list-img{
            position: relative;
            min-height: 80px;
            
            .topTitle{
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                line-height: 40px;
                padding: 0 10px;
            }
        }
        .bottomTitle{
                line-height: 40px;
                padding: 0 10px;
        }
    }
}
</style>