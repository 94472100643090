import Layout from '../layout/components/mainbox.vue'

export const adminroutes = [
	{
		component: "index/index.vue",
		guard_name: "api",
		icon: "shouye",
		layout: "1",
		name: "首页",
		path: "/index",
		props: null,
		router_name: "index",
		vue_name: "index",
		app_level: 0,
		_child: [],
	},
	{
		component: "customer/customer.vue",
		guard_name: "api",
		icon: "yonghu",
		layout: "1",
		name: "客户",
		path: "/customer",
		props: null,
		router_name: "customer",
		vue_name: "customer",
		app_level: 0,
		_child: [],
	},
	{
		component: "Layout",
		guard_name: "api",
		icon: "xiangmu",
		layout: "1",
		name: "项目",
		path: "",
		props: null,
		router_name: "project",
		vue_name: "project",
		app_level: 0,
		_child: [
			{
				component: "project/projectdata.vue",
				layout: "1",
				name: "项目概况",
				path: "/project/projectdata",
				router_name: "project",
				vue_name: "projectdata",
				app_level: 0,
			},
			{
				component: "project/project.vue",
				layout: "1",
				name: "正常进度",
				path: "/project/normal",
				router_name: "project",
				vue_name: "projectnormal",
				app_level: 0,
				props: 3,
			},
			{
				component: "project/project.vue",
				layout: "1",
				name: "即将交付",
				path: "/project/soon",
				router_name: "project",
				vue_name: "projectsoon",
				app_level: 0,
				props: 4,
			},
			{
				component: "project/project.vue",
				layout: "1",
				name: "超期项目",
				path: "/project/overdue",
				router_name: "project",
				vue_name: "projectoverdue",
				app_level: 0,
				props: 5,
			},
			{
				component: "project/project.vue",
				layout: "1",
				name: "交付项目",
				path: "/project/deliver",
				router_name: "project",
				vue_name: "projectdeliver",
				app_level: 0,
				props: 2,
			},
			{
				component: "project/project.vue",
				layout: "1",
				name: "解除合作",
				path: "/project/relieve",
				router_name: "project",
				vue_name: "projectrelieve",
				app_level: 0,
				props: 0,
			},
			{
				component: "project/addproject.vue",
				layout: "2",
				name: "添加项目",
				path: "/project/add",
				router_name: "project",
				vue_name: "projectadd",
				app_level: 0,
				props: 6,
			},
		],
	},
	{
		component: "order/order.vue",
		guard_name: "api",
		icon: "dingdan",
		layout: "1",
		name: "订单",
		path: "/order",
		props: null,
		router_name: "order",
		vue_name: "order",
		app_level: 0,
		_child: []
	},
	{
		component: "Layout",
		guard_name: "api",
		icon: "caiwu",
		layout: "1",
		name: "财务",
		path: "",
		props: null,
		router_name: "finance",
		vue_name: "finance",
		app_level: 0,
		_child: [
			{
				component: "finance/index.vue",
				layout: "1",
				name: "财务数据",
				path: "/finance/index",
				router_name: "finance",
				vue_name: "financeindex",
				app_level: 0,
			},
			{
				component: "finance/profit.vue",
				layout: "1",
				name: "盈亏数据",
				path: "/finance/profit",
				router_name: "finance",
				vue_name: "financeprofit",
				app_level: 0,
			},
			{
				component: "finance/collection.vue",
				layout: "1",
				name: "收款单",
				path: "/finance/collection",
				router_name: "finance",
				vue_name: "financeCollection",
				app_level: 0,
			},
			{
				component: "finance/payment.vue",
				layout: "1",
				name: "付款单",
				path: "/finance/payment",
				router_name: "finance",
				vue_name: "financePayment",
				app_level: 0,
			},
			{
				component: "finance/loan.vue",
				layout: "1",
				name: "借款单",
				path: "/finance/loan",
				router_name: "finance",
				vue_name: "financeLoan",
				app_level: 0,
			},
			{
				component: "finance/repayment.vue",
				layout: "1",
				name: "还款单",
				path: "/finance/repayment",
				router_name: "finance",
				vue_name: "finanRepayment",
				app_level: 0,
			},
			{
				component: "finance/waitCollection.vue",
				layout: "1",
				name: "待收款",
				path: "/finance/waitCollection",
				router_name: "finance",
				vue_name: "finanWaitCollection",
				app_level: 0,
			},
			{
				component: "finance/waitPayment.vue",
				layout: "1",
				name: "待付款",
				path: "/finance/waitPayment",
				router_name: "finance",
				vue_name: "finanWaitPayment",
				app_level: 0,
			},
		]
	},
	{
		component: "applist/apps.vue",
		guard_name: "api",
		icon: "el-icon-s-platform",
		layout: "2",
		name: "子应用",
		path: "/applist/apps",
		props: null,
		router_name: "applist",
		vue_name: "appchild",
		app_level: 0,
		_child: []
	},
	{
		component: "case/case.vue",
		guard_name: "api",
		icon: "anli",
		layout: "1",
		name: "案例",
		path: "/case",
		props: null,
		router_name: "case",
		vue_name: "case",
		app_level: 0,
		_child: []
	},
	{
		component: "case/casedetail.vue",
		guard_name: "api",
		icon: "el-icon-s-data",
		layout: "2",
		name: "案例详情",
		path: "/case/detail",
		props: null,
		router_name: "case",
		vue_name: "casedetail",
		app_level: 0,
		_child: []
	},
	{
		component: "case/addcase.vue",
		guard_name: "api",
		icon: "el-icon-s-comment",
		layout: "2",
		name: "添加案例",
		path: "/case/add",
		props: null,
		router_name: "case",
		vue_name: "caseadd",
		app_level: 0,
		_child: []
	},
	{
		component: "notice/notice.vue",
		guard_name: "api",
		icon: "gonggao",
		layout: "1",
		name: "公告",
		path: "/notice",
		props: null,
		router_name: "notice",
		vue_name: "notice",
		app_level: 0,
		_child: []
	},
	{
		component: "notice/addnotice.vue",
		guard_name: "api",
		icon: "el-icon-s-comment",
		layout: "2",
		name: "添加公告",
		path: "/notice/add",
		props: null,
		router_name: "notice",
		vue_name: "noticeadd",
		app_level: 0,
		_child: []
	},
	{
		component: "notice/noticedetail.vue",
		guard_name: "api",
		icon: "el-icon-s-comment",
		layout: "2",
		name: "公告详情",
		path: "/notice/detail",
		props: null,
		router_name: "notice",
		vue_name: "noticedetail",
		app_level: 0,
		_child: []
	},
	// {
	// 	component: "Layout",
	// 	guard_name: "api",
	// 	icon: "zhinan",
	// 	layout: "1",
	// 	name: "指南",
	// 	path: "",
	// 	props: null,
	// 	router_name: "help",
	// 	vue_name: "help",
	// 	app_level:0,
	// 	_child: [
	// 		{
	// 			component: "help/help.vue",
	// 			layout: "1",
	// 			name: "添加分类",
	// 			path: "/help/addclass",
	// 			router_name: "help",
	// 			vue_name: "helpaddclass",
	// 			app_level:0,
	// 		},
	// 		{
	// 			component: "help/addhelp.vue",
	// 			layout: "1",
	// 			name: "添加指南",
	// 			path: "/help/add",
	// 			router_name: "help",
	// 			vue_name: "helpadd",
	// 			app_level:0,
	// 		},
	// 		{
	// 			component: "help/helplist.vue",
	// 			layout: "1",
	// 			name: "指南列表",
	// 			path: "/help/list",
	// 			router_name: "help",
	// 			vue_name: "helplist",
	// 			app_level:0,
	// 		}
	// 	]	
	// },
	{
		component: "Layout",
		guard_name: "api",
		icon: "qudao",
		layout: "1",
		name: "代理",
		path: "",
		props: null,
		router_name: "agent",
		vue_name: "agent",
		app_level: 0,
		_child: [
			{
				component: "agent/list.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "代理管理",
				path: "/agent/list",
				props: null,
				router_name: "agent",
				vue_name: "agentList",
				app_level: 0,
				_child: []
			},
			{
				component: "agent/add.vue",
				guard_name: "api",
				icon: null,
				layout: "2",
				name: "添加代理",
				path: "/agent/add",
				props: null,
				router_name: "agent",
				vue_name: "agentAdd",
				app_level: 0,
				_child: []
			},
			{
				component: "agent/recharge.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "充值记录",
				path: "/agent/recharge",
				props: null,
				router_name: "agent",
				vue_name: "agentRecharge",
				app_level: 0,
				_child: []
			},
			// {
			// 	component: "agent/consumption.vue",
			// 	guard_name: "api",
			// 	icon: null,
			// 	layout: "1",
			// 	name: "消费记录",
			// 	path: "/agent/consumption",
			// 	props: null,
			// 	router_name: "agent",
			// 	vue_name: "agentConsumption",
			// 	app_level:0,
			// 	_child: []
			// },
			{
				component: "Layout",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "提现管理",
				path: "",
				props: null,
				router_name: "agent",
				vue_name: "withdrawal",
				app_level: 0,
				_child: [
					{
						component: "agent/withdrawal.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "待审核",
						path: "/withdrawal/wait",
						props: 0,
						router_name: "agent",
						vue_name: "withdrawalWait",
						app_level: 0,
						_child: []
					},
					{
						component: "agent/withdrawal.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "已通过",
						path: "/withdrawal/adopt",
						props: 1,
						router_name: "agent",
						vue_name: "withdrawalWait",
						app_level: 0,
						_child: []
					},
					{
						component: "agent/withdrawal.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "未通过",
						path: "/withdrawal/refuse",
						props: 2,
						router_name: "agent",
						vue_name: "withdrawalWait",
						app_level: 0,
						_child: []
					},
					{
						component: "agent/withdrawal.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "已打款",
						path: "/withdrawal/complete",
						props: 3,
						router_name: "agent",
						vue_name: "withdrawalWait",
						app_level: 0,
						_child: []
					}
				]
			},
			{
				component: "agent/set.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "基础设置",
				path: "/agent/set",
				props: null,
				router_name: "agent",
				vue_name: "agentSet",
				app_level: 0,
				_child: []
			},
		]
	},
	{
		component: "agent/data.vue",
		guard_name: "api",
		icon: "gaikuang",
		layout: "1",
		name: "数据",
		path: "/data/data",
		props: null,
		router_name: "data",
		vue_name: "data",
		app_level: 0,
		_child: []
	},
	{
		component: "applist/applist.vue",
		guard_name: "api",
		icon: "yingyong",
		layout: "1",
		name: "应用",
		path: "/applist",
		props: null,
		router_name: "applist",
		vue_name: "applist",
		app_level: 0,
		_child: []
	},
	{
		component: "applist/appsmenu.vue",
		guard_name: "api",
		icon: "el-icon-s-platform",
		layout: "2",
		name: "应用菜单",
		path: "/applist/appsmenu",
		props: null,
		router_name: "applist",
		vue_name: "appsmenu",
		app_level: 0,
		_child: []
	},
	{
		component: "Layout",
		guard_name: "api",
		icon: "shezhi",
		layout: "1",
		name: "设置",
		path: "",
		props: null,
		router_name: "account",
		vue_name: "account",
		app_level: 0,
		_child: [
			{
				component: "set/basics.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "基础设置",
				path: "/set/basics",
				props: null,
				router_name: "account",
				vue_name: "basics",
				app_level: 0,
				_child: []
			},
			{
				component: "account/editaccount.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "修改账号",
				path: "/account/editaccount",
				props: null,
				router_name: "account",
				vue_name: "editaccount",
				app_level: 0,
				_child: []
			},
			{
				component: "account/editpassword.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "修改密码",
				path: "/account/editpassword",
				props: null,
				router_name: "account",
				vue_name: "editpassword",
				app_level: 0,
				_child: []
			}
		]
	},
]