import Layout from '../layout/components/mainbox.vue'

export const agentroutes = [
	{
		component: "apps/agent/index/index.vue",
		guard_name: "api",
		icon: "shouye",
		layout: "1",
		name: "首页",
		path: "/index",
		props: null,
		router_name: "index",
		vue_name: "index",
		app_level:0,
		_child: [],
	},
	{
		component: "apps/agent/customer/customer.vue",
		guard_name: "api",
		icon: "yonghu",
		layout: "1",
		name: "客户",
		path: "/customer",
		props: null,
		router_name: "customer",
		vue_name: "customer",
		app_level:0,
		_child: [],	
	},
	{
		component: "apps/agent/order/order.vue",
		guard_name: "api",
		icon: "dingdan",
		layout: "1",
		name: "订单",
		path: "/order",
		props: null,
		router_name: "order",
		vue_name: "order",
		app_level:0,
		_child: []
	},
	{
		component: "Layout",
		guard_name: "api",
		icon: "caiwu",
		layout: "1",
		name: "财务",
		path: "",
		props: null,
		router_name: "finance",
		vue_name: "finance",
		app_level:0,
		_child: [
			{
				component: "apps/agent/finance/data.vue",
				layout: "1",
				name: "财务数据",
				path: "/finance/data",
				router_name: "finance",
				vue_name: "financeData",
				app_level:0,
			},
			{
				component: "apps/agent/finance/rechargeRe.vue",
				layout: "1",
				name: "充值记录",
				path: "/finance/rechargeRe",
				router_name: "finance",
				vue_name: "financeRechargeRe",
				app_level:0,
			},
			{
				component: "apps/agent/finance/consumptionRe.vue",
				layout: "1",
				name: "消费记录",
				path: "/finance/consumptionRe",
				router_name: "finance",
				vue_name: "financeConsumptionRe",
				app_level:0,
			},
			{
				component: "apps/agent/finance/detailed.vue",
				layout: "1",
				name: "订单明细",
				path: "/finance/detailed",
				router_name: "finance",
				vue_name: "financeDetailed",
				app_level:0,
			},
			{
				component: "Layout",
				layout: "1",
				name: "提现记录",
				path: "/finance/withdrawalRe",
				router_name: "finance",
				vue_name: "financeWithdrawalRe",
				app_level:0,
				_child:[
					{
						component: "apps/agent/finance/withdrawalRe.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "待审核",
						path: "/withdrawal/wait",
						props: 0, 
						router_name: "finance",
						vue_name: "withdrawalWait",
						app_level:0,
						_child: []
					},
					{
						component: "apps/agent/finance/withdrawalRe.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "已通过",
						path: "/withdrawal/adopt",
						props: 1,
						router_name: "finance",
						vue_name: "withdrawalWait",
						app_level:0,
						_child: []
					},
					{
						component: "apps/agent/finance/withdrawalRe.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "未通过",
						path: "/withdrawal/refuse",
						props: 2,
						router_name: "finance",
						vue_name: "withdrawalWait",
						app_level:0,
						_child: []
					},
					{
						component: "apps/agent/finance/withdrawalRe.vue",
						guard_name: "api",
						icon: null,
						layout: "1",
						name: "已打款",
						path: "/withdrawal/complete",
						props: 3,
						router_name: "finance",
						vue_name: "withdrawalWait",
						app_level:0,
						_child: []
					}
				]
			}
		]
	},
	{
		component: "apps/agent/application/applist.vue",
		guard_name: "api",
		icon: "yingyong",
		layout: "1",
		name: "应用",
		path: "/application",
		props: null,
		router_name: "application",
		vue_name: "application",
		app_level:0,
		_child: []
	},
	{
		component: "Layout",
		guard_name: "api",
		icon: "shezhi",
		layout: "1",
		name: "设置",
		path: "",
		props: null,
		router_name: "set",
		vue_name: "set",
		app_level:0,
		_child: [
			{
				component: "apps/agent/set/basic.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "基础设置",
				path: "/set/basics",
				props: null,
				router_name: "set",
				vue_name: "setBasic",
				app_level:0,
				_child: []
			},
			{
				component: "apps/agent/set/editaccount.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "修改账号",
				path: "/account/editaccount",
				props: null,
				router_name: "set",
				vue_name: "editaccount",
				app_level:0,
				_child: []
			},
			{
				component: "apps/agent/set/editpassword.vue",
				guard_name: "api",
				icon: null,
				layout: "1",
				name: "修改密码",
				path: "/account/editpassword",
				props: null,
				router_name: "set",
				vue_name: "editpassword",
				app_level:0,
				_child: []
			}
		]		
	},
]