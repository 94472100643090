<template>
	<div class="plugins" :style="{'padding':plugins_data.style.margin/2+'px 0'}">
		<div class="guide" :style="{'border-bottom-width':plugins_data.style.height/2+'px','border-bottom-style':plugins_data.config.style,'border-bottom-color':plugins_data.style.color}"></div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'guide',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
	
</style>
