<template>
  <div class='plugins'>
    <div class="imgbox" :style="{
			'paddingTop':plugins_data.style.padding_top/2+'px',
			'paddingBottom':plugins_data.style.padding_bottom/2+'px',
			'paddingLeft':plugins_data.style.padding_left/2+'px',
			'paddingRight':plugins_data.style.padding_left/2+'px'
		}">
      <div style="display: flex;">
        <div class="contentbox" :style="{
					'height':plugins_data.video.type==1?'197px':plugins_data.video.type==2?(plugins_data.video.styleType==1?'351px':'115px'):'263px',
					'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
					}">
          <video @click="playVideo" :id="video_id" class="content" :src="plugins_data.video.src" :style="{'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px'}">
            当前浏览器不支持 video直接播放
          </video>
          <img v-show="plugins_data.video.image&&!is_pay" class="img" :src="plugins_data.video.image">
          <div v-show="!is_pay" class="modal" :style="{
						'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
					}"><i class="playicon el-icon-video-play" @click="playVideo"></i></div>
        </div>
        <div v-if="plugins_data.video.styleType==2" class="textbox">
          <div class="title" :style="{'color':plugins_data.video.titleColor}">{{plugins_data.video.title||'请输入视频标题'}}</div>
          <div class="desc" :style="{'color':plugins_data.video.descColor}">{{plugins_data.video.desc||'请输入视频描述'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoView',
  data() {
    return {
      is_pay: false,
      video_id: new Date().getTime()
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  methods: {
    playVideo() {
      let videos = document.getElementById(this.video_id)
      if (videos.paused) {
        videos.play()
        this.is_pay = true
      } else {
        videos.pause()
        this.is_pay = false
      }
    }
  }
};
</script>

<style lang="less" scoped>
.imgbox {
  .contentbox {
    flex: 1;
    position: relative;
    overflow: hidden;
    .content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: fill;
    }
    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
    .modal {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      .playicon {
        font-size: 70px;
        color: #ffffff;
      }
    }
  }
  .textbox {
    flex: 2;
    width: 50%;
    padding-left: 8px;
    .title {
      overflow: hidden;
      display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 */
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 15px;
      font-weight: bold;
    }
    .desc {
      margin-top: 4px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      display: -webkit-box; /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 */
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
