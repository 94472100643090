<template>
	<div class='plugins'>
		<div>
			<div class="headsty">公告图标</div>
			<div class="box">
				<div class="label">添加图标:</div>
				<div class="imagebox" @click="imgshow = true">
					<el-image class="image" v-if="plugins_data.data.image" :src="plugins_data.data.image" fit="cover"></el-image>
					<div class="addbox" v-else>
						<i class="el-icon-plus addicon"></i>
						<div class="addtext">添加图片</div>
					</div>
				</div>
			</div>
		</div>
		
		<div>
			<div class="headsty">公告内容</div>
			<div class="box" style="align-items: flex-start;">
				<div class="label">添加公告:</div>
				<div style="flex: 1;">
					<el-button @click="addNotice" type="primary" size="small" style="margin-bottom: 10px;">添加公告</el-button>
					<div v-for="(item,index) in plugins_data.data.list" :key="index" class="inputbox">
						<el-input type="text" v-model="item.msg" style="width: 100%;">
							<el-button slot="append" icon="el-icon-delete" @click="delNotice(index)"></el-button>
						</el-input>
					</div>
				</div>
				
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">文字颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.color"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">上圆角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下圆角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<!-- <div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div> -->
		</div>
		
		<xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
	</div>
</template>

<script>
	export default {
		name: 'noticeData',
		data() {
			return {
				imgshow:false,
				pickIndex:null,
			};
		},
		props: {
			plugins_data: { type: Object },
		},
		methods:{
			addNotice(){
				this.plugins_data.data.list.push({
					masg:'',
					link:'',
				})
			},
			delNotice(index){
				this.plugins_data.data.list.splice(index,1)
			},
			//选择图片
			pickimg(e){
				this.plugins_data.data.image = e.imgobjs[0].qiniu_url
			},
		}
	};
</script>

<style>
	#xskswiper .el-input.is-disabled .el-input__inner{
		color: #333 !important;
	}
</style>
<style lang="less" scoped>
	@import "../../style/pluginsCommon.css";
	
	.imagebox{
		flex: 0 0 80px;
		width: 80px;
		height: 80px;
		.image{
			width: 100%;
			height: 100%;
		}
		.addbox{
			width: 100%;
			height: 100%;
			border: 1px solid #e3e3e3;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			.addicon{
				font-size: 20px;
				color: #1989fa;
			}
			.addtext{
				margin-top: 4px;
				font-size: 12px;
				color: #999999;
			}
		}
	}

	.addbtn{
		line-height: 32px;
		text-align: center;
		border: 1px solid #dcdee2;
		color: #409eff;
		margin-bottom: 20px;
		&:hover{
			border-color: #409eff;
		}
	}

	.inputbox{
		margin-bottom: 12px;
		&:last-child{
			margin-bottom: 0;
		}
	}
</style>
