<template>
  <div class="content">
    <div
      class="meheadcontent"
      :style="{
        'padding-top': '56px',
      }"
    >
      <img class="bgimg" v-if="plugins_data.data.image" :src="plugins_data.data.image"/>
      <div class="textbox">
        <div class="avatarbox">
          <img class="avatarimg" src="@/assets/vip/user-avatar.png" />
        </div>
        <div class="infobox">
          <div class="name">
            <div class="text" :style="{ color: '#333' }">
              {{ "登录/注册" }}
            </div>
          </div>
          <div class="iphone" :style="{ color: '#333' }">
            <div class="text">欢迎来到****</div>
          </div>
        </div>
      </div>
    </div>

    <div class="orderContent">
      <div class="headbox">
        <div class="title">我的订单</div>
        <div class="rightbox">
          <div class="text">查看全部</div>
          <!-- <uni-icons type="arrowright" size="32" color="#333333"></uni-icons> -->
        </div>
      </div>
      <div class="btnbox">
        <div class="box">
          <div class="badge"></div>
          <img class="icon" src="@/assets/vip/icon-daifukuan.png" />
          <div class="text">待付款</div>
        </div>
        <div class="box">
          <div class="badge"></div>
          <img class="icon" src="@/assets/vip/icon-daifahuo.png" />
          <div class="text">待发货</div>
        </div>
        <div class="box">
          <div class="badge"></div>
          <img class="icon" src="@/assets/vip/icon-daishouhuo.png" />
          <div class="text">待收货</div>
        </div>
        <div class="box">
          <div class="badge"></div>
          <img class="icon" src="@/assets/vip/icon-yiwancheng.png" />
          <div class="text">已完成</div>
        </div>
        <div class="box">
          <div class="badge"></div>
          <img class="icon" src="@/assets/vip/icon-shouhou.png" />
          <div class="text">售后</div>
        </div>
      </div>
    </div>
 
  </div>
</template>

<script>
export default {
	props: {
		plugins_data: { type: Object }
	},
  };
</script>

<style lang="scss" scoped>
.content {
//   padding-bottom: 50px;

  .meheadcontent {
    position: relative;
    background: linear-gradient(to bottom, #bcd9f3, #e5fbf2, #f5edfa, #f8f8f8);
    z-index: 1;
    .bgimg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .textbox {
      position: relative;
      z-index: 2;
      padding: 16px 15px;
      display: flex;
      align-items: center;
      z-index: 9;
      .avatarbox {
        flex: 0 0 64px;
        width: 64px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .avatarimg {
          flex: 0 0 64px;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 2px solid #ffffff;
        }
        .tag {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 4px;
          height: 18px;
          font-size: 10px;
          color: #333333;
          border-radius: 10px;
          border: 1px solid #333333;
          position: absolute;
          bottom: 14px;
        }
      }
      .infobox {
        flex: 1;
        .name {
          display: flex;
          align-items: center;
          .text {
            font-size: 17px;
            color: #000;
            font-weight: bolder;
          }
          .level {
            width: 75px;
            height: 20px;
            position: relative;
            margin-left: 10px;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
            text {
              display: block;
              position: absolute;
              left: 5px;
              top: 0;
              font-size: 12px;
              color: #fff;
              width: 100%;
              height: 100%;
              line-height: 17px;
              text-align: center;
              padding-left: 5px;
            }
          }
        }
        .iphone {
          margin-top: 5px;
          display: flex;
          align-items: center;
          .text {
            font-size: 12px;
            color: #000;
          }
          .tag {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            height: 18px;
            font-size: 10px;
            color: #333333;
            border: 1px solid #333333;
            border-radius: 10px;
            margin-left: 6px;
          }
        }
      }
      .btnbox {
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          height: 24px;
          margin-left: 12px;
          &:first {
            margin-left: 0;
          }
        }
      }
    }
    .price {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 20px;
      div {
        width: calc(100% / 3 - 10px);
        text {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 12px;
          color: #666;
          &:first-child {
            font-size: 17px;
            color: #000;
            margin-bottom: 5px;
            font-weight: bolder;
          }
        }
      }
      .cut {
        position: relative;
        .tx {
          width: auto;
          padding: 2px 6px;
          border-radius: 25px;
          background-color: #ea452f;
          color: #fff;
          font-size: 12px;
          position: absolute;
          top: -13px;
          right: 0px;
          z-index: 1;
          &::before {
            content: "";
            width: 0;
            height: 0;
            border-left: 0px solid transparent;
            border-right: 9px solid transparent;
            border-top: 12px solid #ea452f;
            position: absolute;
            left: 10px;
            bottom: -6px;
            transform: rotate(10deg);
            z-index: -1;
          }
        }
      }
    }
  }
  .orderContent {
    margin: 15px 14px;
    padding: 18px;
    background-color: #ffffff;
    border-radius: 16px;
  }
  .headbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      color: #333333;
      font-weight: bold;
    }
    .rightbox {
      display: flex;
      align-items: center;
      .text {
        font-size: 12px;
        line-height: 12px;
        color: #333333;
        position: relative;
        top: -2px;
      }
    }
  }
  .btnbox {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .badge {
        position: absolute;
        left: 19px;
        top: -7px;
      }
      .icon {
        width: 29px;
        height: 29px;
        display: block;
      }
      .text {
        margin-top: 4px;
        color: #333333;
        font-size: 12px;
      }
    }
  } 
}
</style>