<template>
	<div id="magic" :style="{
			'margin-top':plugins_data.style.margin_top/2+'px',
			'margin-bottom':plugins_data.style.margin_bottom/2+'px',
			'margin-left':plugins_data.style.margin_right/2+'px',
			'margin-right':plugins_data.style.margin_right/2+'px',
		}">
		<div class="styleList" v-if="plugins_data.type==1">
			<div class="box" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}" v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
				<div v-else class="textStyle">宽度375PX</div>
			</div>
			<div class="box">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}"  v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
				<div v-else class="textStyle">宽度375PX</div>
			</div>
		</div>
		
		<div class="styleList" v-if="plugins_data.type==2">
			<div class="box" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}"  v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
				<div v-else class="textStyle">宽度250PX</div>
			</div>
			<div class="box" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}"  v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
				<div v-else class="textStyle">宽度250PX</div>
			</div>
			<div class="box">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}"  v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
				<div v-else class="textStyle">宽度250PX</div>
			</div>
		</div>
		
		<div class="styleList styleHeight" v-if="plugins_data.type==3">
			<div class="left" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}"  v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
				<div v-else class="textStyle">375*750像素</div>
			</div>
			<div class="right">
				<div class="smallbox" style="border-right: none;" :style="{'margin-bottom':plugins_data.style.padding/2+'px'}">
					<el-image :style="{
						borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
					}" v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
					<div v-else class="textStyle">375*375像素</div>
				</div>
				<div class="smallbox">
					<el-image :style="{
						borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
					}" v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
					<div v-else class="textStyle">375*375像素</div>
				</div>
			</div>
		</div>
		
		<div class="styleList styleHeight styleFour" v-if="plugins_data.type==4">
			<div class="left" :style="{'margin-bottom':plugins_data.style.padding/2+'px'}">
				<el-image :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}" v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
				<div v-else class="textStyle">750*375像素</div>
			</div>
			<div class="right">
				<div class="smallbox" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
					<el-image  :style="{
						borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
					}" v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
					<div v-else class="textStyle">375*375像素</div>
				</div>
				<div class="smallbox">
					<el-image  :style="{
						borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
					}" v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
					<div v-else class="textStyle">375*375像素</div>
				</div>
			</div>
		</div>
		
		<div class="styleList styleHeight" v-if="plugins_data.type==5">
			<div class="left" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
				<el-image  :style="{
					borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
				}" v-if="plugins_data.data[0].image" class="img" :src="plugins_data.data[0].image" fit="cover"></el-image>
				<div v-else class="textStyle">375*750像素</div>
			</div>
			<div class="right">
				<div class="smallbox" style="border-right: none;" :style="{'margin-bottom':plugins_data.style.padding/2+'px'}">
					<el-image  :style="{
						borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
					}" v-if="plugins_data.data[1].image" class="img" :src="plugins_data.data[1].image" fit="cover"></el-image>
					<div v-else class="textStyle">375*375像素</div>
				</div>
				<div class="bottombox">
					<div class="smallbox" style="border-bottom: none;" :style="{'margin-right':plugins_data.style.padding/2+'px'}">
						<el-image  :style="{
							borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
						}" v-if="plugins_data.data[2].image" class="img" :src="plugins_data.data[2].image" fit="cover"></el-image>
						<div v-else class="textStyle">188*375像素</div>
					</div>
					<div class="smallbox">
						<el-image  :style="{
							borderRadius:plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_top/2+'px '+plugins_data.style.radius_bottom/2+'px '+plugins_data.style.radius_bottom/2+'px',
						}" v-if="plugins_data.data[3].image" class="img" :src="plugins_data.data[3].image" fit="cover"></el-image>
						<div v-else class="textStyle">188*375像素</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'magic',
	data() {
		return {
			
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	methods:{
		
	}
};
</script>

<style scoped lang="less">
#magic{
	// border: 1px solid #2d8cf0 !important;
	overflow: hidden;
	.styleList{
		display: flex;
		align-items: center;
		justify-content: center;
		height: 187.5px;
		.box{
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 1;
			width: 100% !important;
			height: 100%;
			border-right: 1px solid #999999;
			&:last-child{
				border-right: none;
			}
			.img{
				width: 100%;
				height: 100%;
			}
		}
		.textStyle{
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #E3E3E3;
			font-size: 12px;
			color: #666666;
		}
		.left{
			flex: 1;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-right: 1px solid #999999;
			.img{
				width: 100%;
				height: 100%;
			}
		}
		.right{
			flex: 1;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			.bottombox{
				width: 100%;
				display: flex;
			}
			.smallbox{
				flex: 1;
				width: 100%;
				height: 187.5px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-right: 1px solid #999999;
				border-bottom: 1px solid #999999;
				&:last-child{
					border-right: none;
					border-bottom: none;
				}
				.img{
					width: 100%;
					height: 100%;
				}
			}
		}
	}
	
	.styleFour{
		flex-direction: column !important;
		.left{
			border-bottom: 1px solid #999999;
			border-right: none;
			height: 50%;
		}
		.right{
			flex-direction: row !important;
			.smallbox{
				border-bottom: none;
			}
		}
	}
	.noborder{
		border: none;
	}
	.styleHeight{
		height: 375px;
	}
}

</style>
