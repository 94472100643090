<template>
  <div class="plugins">
    <div>
      <div class="headsty">添加图片</div>
      <div class="box">
        <div>
          <div class="imagebox" @click="imgshow = true">
            <el-image
              class="image"
              v-if="plugins_data.data.image"
              :src="plugins_data.data.image"
              fit="cover"
            ></el-image>
            <div class="addbox" v-else>
              <i class="el-icon-plus addicon"></i>
              <div class="addtext">添加图片</div>
            </div>
          </div>
          <div class="tips">建议图片尺寸为750x450像素</div>
        </div>
      </div>
    </div>

    <xsk-manage
      :managehide.sync="imgshow"
      is_rest
      @confirm="pickimg"
    ></xsk-manage>
  </div>
</template>

<script>
export default {
  name: "noticeData",
  data() {
    return {
      imgshow: false,
      pickIndex: null,
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  methods: {
    addNotice() {
      this.plugins_data.data.list.push({
        masg: "",
        link: "",
      });
    },
    delNotice(index) {
      this.plugins_data.data.list.splice(index, 1);
    },
    //选择图片
    pickimg(e) {
      this.plugins_data.data.image = e.imgobjs[0].qiniu_url;
    },
  },
};
</script>

<style>
#xskswiper .el-input.is-disabled .el-input__inner {
  color: #333 !important;
}
</style>
<style lang="less" scoped>
@import "../../style/pluginsCommon.css";
.box{
	border-bottom: 1px solid #e9edef;
	padding-bottom: 20px;
	.tips{
		font-size: 12px;
		line-height: 30px;
	}
}
.imagebox {
  width: 125px;
  height: 75px;
  .image {
    width: 100%;
    height: 100%;
  }
  .addbox {
    width: 100%;
    height: 100%;
    border: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .addicon {
      font-size: 20px;
      color: #1989fa;
    }
    .addtext {
      margin-top: 4px;
      font-size: 12px;
      color: #999999;
    }
  }
}

.addbtn {
  line-height: 32px;
  text-align: center;
  border: 1px solid #dcdee2;
  color: #409eff;
  margin-bottom: 20px;
  &:hover {
    border-color: #409eff;
  }
}

.inputbox {
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
