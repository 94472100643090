<template>
	<div id="storechoose">
		<div class="box" :style="{
			'paddingLeft':plugins_data.style.padding_left/2+'px',
			'paddingRight':plugins_data.style.padding_left/2+'px',
			'paddingTop':plugins_data.style.padding_top/2+'px',
			'paddingBottom':plugins_data.style.padding_top/2+'px',
			'marginLeft':plugins_data.style.margin_left/2+'px',
			'marginRight':plugins_data.style.margin_left/2+'px',
			'marginTop':plugins_data.style.margin_top/2+'px',
			'marginBottom':plugins_data.style.margin_bottom/2+'px',
			'background':plugins_data.style.box_bg_type===1?'rgba(0,0,0,0)':plugins_data.style.box_background,
			'borderRadius':plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_bottom/2+'px '+plugins_data.style.box_radius_bottom/2+'px',
		}">
			<div class="left">
				<el-image class="icon" :src="plugins_data.data.left_icon" fit="cover"></el-image>
				<div class="text" :style="{'color': plugins_data.style.text_color}">{{userinfo.store_name}}</div>
				<i class="el-icon-arrow-right" :style="{'color': plugins_data.style.text_color}"></i>
			</div>
			<div class="right">
				<el-image class="icon" :src="plugins_data.data.msg_icon" fit="cover"></el-image>
				<el-image class="icon" :src="plugins_data.data.code_icon" fit="cover"></el-image>
			</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'storechoose',
	data() {
		return {
			userinfo:JSON.parse(sessionStorage.getItem('userinfo'))
		};
	},
	props: {
		plugins_data: { type: Object },
	},
	methods:{
		
	}
};
</script>

<style scoped lang="less">
#storechoose{
	.box{
		display: flex;
		align-items: center;
		justify-content: space-between;
		.left{
			display: flex;
			align-items: center;
			.icon{
				width: 18px;
				height: 18px;
				margin-right: 6px;
			}
			.text{
				font-size: 12px;
				line-height: 12px;
				color:#202020;
			}
		}
		.right{
			display: flex;
			align-items: center;
			.icon{
				margin-right: 12px;
				width: 22px;
				height: 22px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}
</style>
