<template>
  <div id="index">
    <div class="newsadd">
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.total}}</div>
          <div class="text">总客户数</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-zkh.png')" fit="contain"></el-image>
      </div>
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.today}}</div>
          <div class="text">今日新增</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-jrxz.png')" fit="contain"></el-image>
      </div>
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.yesterday}}</div>
          <div class="text">昨日新增</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-zrxz.png')" fit="contain"></el-image>
      </div>
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.week}}</div>
          <div class="text">近七日新增</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-qrxz.png')" fit="contain"></el-image>
      </div>
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.due_soon}}</div>
          <div class="text">即将过期</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-jjdq.png')" fit="contain"></el-image>
      </div>
      <div class="box">
        <div class="textinfo">
          <div class="number">{{topData.expired}}</div>
          <div class="text">已过期</div>
        </div>
        <el-image class="icon" :src="require('assets/icon/icon-ydq.png')" fit="contain"></el-image>
      </div>
    </div>

    <div class="echartsbox">
      <div class="titlebox">
        <div class="text">
          经营状况
          <span>数据更新于{{ year }}-{{ month }}-{{ day }}，每日更新一次</span>
        </div>
        <div class="timebox">
          <div class="changebox">
            <div :class="{ active: type == 'day' }" @click="changetype('day')">日</div>
            <div :class="{ active: type == 'month' }" @click="changetype('month')">月</div>
          </div>

          <el-date-picker v-show="type == 'day'" v-model="picktime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="changeTime"></el-date-picker>
          <el-date-picker v-show="type == 'month'" v-model="picktime" type="monthrange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM" format="yyyy-MM" @change="changeTime"></el-date-picker>

        </div>
      </div>

      <!-- <div class="listbox">
        <div class="list" :class="{ active: filed == item.filed }" @click="changeEcharts(item.filed)" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="number">{{ item.number }}</div>
        </div>
      </div> -->
      <div class="echarts" id="echarts1"></div>
    </div>

  </div>
</template>

<script>
import echarts from 'echarts';
import { eltips, setEchartLine } from '@/util/util.js';
export default {
  name: 'index',
  data() {
    return {
      type: 'day',
      list: [],
      filed: '',
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
      picktime: '',
      topData: '',
    };
  },
  methods: {
    changeTime(e) {
      this.setcharts()
      this.getCount()
    },
    changetype(type) {
      this.type = type;
      this.picktime = [];
      this.setcharts();
      this.getCount();
    },
    changeEcharts(filed) {
      if (this.filed != filed) {
        this.filed = filed;
        this.setcharts();
        this.getCount();
      }
    },
    //折线图
    setcharts() {
      let data = {
        type: this.type,
        unitid: this.filed
      };
      if (this.picktime && this.picktime.length == 2) {
        data.start_time = this.picktime[0]
        data.end_time = this.picktime[1]

        if (this.type == 'month') {
          data.start_time = `${this.picktime[0]}-01`
          data.end_time = `${this.picktime[1]}-31`
        }
      }
      this.axios.get('/api/home_page_line', { params: data }).then(res => {
        if (res.code == 200) {
          let x_data = [];
          let y_data = [];
          res.data.forEach(item => {
            x_data.push(item.time);
            y_data.push(item.count);
          });
          setEchartLine({
            el_id: 'echarts1',
            y_data: y_data,
            x_data: x_data
          });
        } else {
          eltips(res.msg, 'error');
        }
      });
    },
    //折线数量
    getCount(cover) {
      let data = {};
      if (this.picktime && this.picktime.length == 2) {
        data.start_time = this.picktime[0]
        data.end_time = this.picktime[1]
      }
      this.axios.get('/api/home_page_increase', { params: data }).then(res => {
        if (res.code == 200) {
          this.list = []
          res.data.map(item => {
            this.list.push({
              title: item.app_name,
              number: item.user_count,
              filed: item.id
            })
          })

          if (cover) {
            this.filed = res.data[0].id
            this.setcharts()
          }
        } else {
          eltips(res.msg, 'error');
        }
      });
    },
    //头部统计
    getTopCount() {
      this.axios.get('/api/home_page_statistics').then(res => {
        if (res.code == 200) {
          this.topData = res.data
        } else {
          eltips(res.msg, 'error');
        }
      });
    },

  },
  created() {
    this.getTopCount()
  },
  mounted() {
    this.getCount('cover');
    // this.setcharts();
  }
};
</script>

<style lang="less" scoped>
#index {
  min-height: calc(100vh - 70px);
}

.newsadd {
  margin-bottom: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  .box {
    flex: 1;
    height: 100px;
    margin-right: 20px;
    background: #ffffff;
    padding-left: 40px;
    padding-right: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid #e9edef;
    background: linear-gradient(180deg, #f7f8ff 0%, #fff 100%);

    &:last-child {
      margin-right: 0;
    }
    .icon {
      flex: 0 50px;
      display: inline-block;
      vertical-align: top;
      height: 50px;
      border-radius: 50%;
      margin-left: 20px;
    }
    .textinfo {
      flex: auto;
      display: inline-block;
      vertical-align: top;
      .text {
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #636669;
      }
      .number {
        font-size: 32px;
        font-weight: bold;
        line-height: 45px;
        color: #262b30;
      }
    }
  }
}

.echartsbox {
  margin-top: 10px;
  padding: 0 20px 20px;
  background: #ffffff;
  border-radius: 3px;
  overflow: hidden;
  .titlebox {
    overflow: hidden;
    .text {
      float: left;
      line-height: 40px;
      font-size: 16px;
      font-weight: bold;
      span {
        font-size: 12px;
        font-weight: normal;
        padding-left: 10px;
      }
    }
    .timebox {
      float: right;
      .changebox {
        // border: 1px solid #E7E7E7;
        display: inline-block;
        margin-right: 20px;
        div {
          display: inline-block;
          width: 40px;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          border: 1px solid #e7e7e7;
          cursor: pointer;
          &:first-child {
            border-right: none;
          }
        }
        .active {
          border: 1px solid #1e92ff !important;
          color: #1e92ff;
        }
      }
    }
  }
  .listbox {
    margin-top: 20px;
    height: 120px;
    // width: 100%;
    border-bottom: none;
    border: 1px solid #e9edef;
    border-bottom: none;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    .list {
      display: inline-block;
      width: 12.5%;
      height: 100px;
      padding: 20px;
      border-right: 1px solid #e9edef;
      border-bottom: 1px solid #e9edef;
      &:last-child {
        border-right: none;
      }
      &:hover {
        border: 1px solid #4a67ff;
      }
      .title {
        font-size: 14px;
      }
      .number {
        margin-top: 10px;
        font-size: 24px;
      }
    }
    .active {
      border: 1px solid #4a67ff !important;
      position: relative;
      .number {
        color: #4a67ff;
      }
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-top: 15px solid #4a67ff;
        border-right: 15px solid transparent;
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -7px;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-top: 13px solid #ffffff;
        border-right: 13px solid transparent;
        position: absolute;
        bottom: -13px;
        left: 50%;
        margin-left: -5px;
        z-index: 1;
      }
    }
  }
  .echarts {
    border: 1px solid #e9edef;
    border-top: none;
    height: 600px;
  }
}

.titlebox {
  background: #ffffff;
  padding: 20px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .label {
    margin-right: 10px;
  }
  .inputbox {
    display: inlin-block;
    width: 200px;
    line-height: 32px;
    height: 32px;
    font-size: 14px;
    margin-right: 30px;
  }
  .pickbox {
    width: 300px;
    margin-right: 30px;
    position: relative;
    top: 1px;
  }
  .selectbox {
    width: 100px;
    margin-right: 30px;
    position: relative;
    top: 1px;
  }
}

.tablebox {
  background: #ffffff;
  padding: 10px 20px;
  .infobox {
    display: flex;
    align-items: center;
    .info {
      text-align: left;
      margin-left: 20px;
      .addres {
        margin-top: 10px;
      }
    }
  }
  .moneybox {
    display: flex;
    .box {
      flex: 1;
      text-align: center;
      .title {
        margin-top: 2px;
        font-size: 16px;
      }
      .price {
        font-size: 20px;
        color: #1e92ff;
      }
    }
  }
}

.pages {
  text-align: center;
  background: #ffffff;
  padding: 20px 0;
  border-top: 1px solid #ededed;
}
</style>
