import axios from 'axios'
import Vue from 'vue';

let bus=new Vue()

export default {
	namespaced: true,
	state: {
		// im聊天记录
		imHistory: [],
		selectUser: '',
		imlogin: false, //im是否已初始化
		conversationList: [],
		// 1是要下拉 2不用
		value:1
	},
	getters: {},
	mutations: {
		// 添加单个聊天记录
		addHistory(state, value) {
			console.log(value, '监听到了')
			if (state.selectUser == '') {
				axios.get("/api/orange_manage/chat/list").then((res) => {
					state.conversationList = res.data
				})
			} else {
				var today = new Date();
				var y = today.getFullYear();
				var m = today.getMonth();
				var d = today.getDate();
				var h = today.getHours();
				var i = today.getMinutes();
				var s = today.getSeconds(); // 在小于10的数字钱前加一个‘0’
				m = m + 1;
				d = d<10?"0" + d:d ;
				m = m<10?"0" + m:m;
				i = i<10?"0" + i:i;
				s = s<10?"0" + s:s;

				let obj = {
					sender_type: 1,
					content: value,
					created_at: y + "-" + m + "-" + d + " " + h + ":" + i + ":" + s,
					sender_avatar:state.selectUser.seeker.avatar
				}

				console.log(obj, '---obj')

				state.imHistory.push(obj)
				
				bus.$emit('scroll-bottom')
			}

		},

		// 选中会话列表
		selectConversation(state, user) {
			state.selectUser = user
			state.imHistory = []
			// state.page = 1
			// state.count = 0
		},
		
		// 赋值
		addimHistory(state,list){
			state.imHistory = list
		},
		
		changeIM(state, status) {
			console.log('改变状态', status)
			state.imlogin = status
		},
		
		changeValue(state,value){
			state.value=value
		},

		addconversation(state, list) {
			state.conversationList = list
		}

	},
	actions: {},
}
