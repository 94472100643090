<template>
  <div class='plugins' id="xskswiper">
    <div>
      <div class="headsty">内容</div>
      <div class="listbox">
        <draggable v-model="plugins_data.banner" :options="{ animation: 200 }">
          <div class="list" v-for="(item,index) in plugins_data.banner" :key="index">
            <i class="el-icon-error delicon" @click="delBanner(index)" v-if="plugins_data.banner.length>1"></i>
            <i class="el-icon-s-operation icon"></i>
            <div class="imagebox" @click="openManage(index)">
              <el-image class="image" v-if="item.images" :src="item.images" fit="cover"></el-image>
              <div class="addbox" v-else>
                <i class="el-icon-plus addicon"></i>
                <div class="addtext">添加图片</div>
              </div>
            </div>
            <div class="right">
              <el-input v-if="item.link" v-model="item.link.data.name" disabled style="color: #333;!important">
                <div slot="prepend"><i class="el-icon-link linkIcon"></i></div>
                <div slot="append">
                  <span class="changebtn" @click="openLink(index)">修改</span>
                  <span class="changebtn" @click="delLink(index)" style="margin-left: 10px;color: #FF0000;">删除</span>
                </div>
              </el-input>
              <div class="addlink" v-else @click="openLink(index)"><i class="el-icon-link"></i> 选择链接</div>
              <div class="tips">建议图片宽度750px，高度按最高的图片变化</div>
            </div>
          </div>
        </draggable>
      </div>
      <div class="addbtn" @click="addbanner">+添加内容</div>
    </div>

    <div>
      <div class="headsty">内容样式</div>
      <div class="box">
        <div class="label">上边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">左右边距:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">上边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
          <template slot="append">PX</template>
        </el-input>
      </div>
      <div class="box">
        <div class="label">下边角:</div>
        <el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
        <el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
          <template slot="append">PX</template>
        </el-input>
      </div>
    </div>

    <xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
    <chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import chooselink from '../../common/chooselink.vue'
export default {
  name: 'swiperData',
  components: { draggable, chooselink },
  data() {
    return {
      imgshow: false,
      pickIndex: null,
      chooseShow: false,
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  methods: {
    //选择链接
    openLink(index) {
      this.pickIndex = index
      this.chooseShow = true
    },
    confirm(e) {
      let item = this.plugins_data.banner[this.pickIndex]
      item.link = e
      // 不提交自定义页面的content  thunmbnail
      if (item.link.type == "diypage") {
        item.link.data.content = null
        item.link.data.thumbnail = null
      }
      this.$set(this.plugins_data.banner, this.pickIndex, item)
    },
    //删除链接
    delLink(index) {
      this.$confirm('此操作将删除该链接, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let item = this.plugins_data.banner[this.pickIndex]
        item.link = ''
        this.$set(this.plugins_data.banner, this.pickIndex, item)
      }).catch(() => { });
    },
    //新增轮播图
    addbanner() {
      this.plugins_data.banner.push({
        images: '',
        link: '',
      })
    },
    //删除轮播图
    delBanner(index) {
      this.plugins_data.banner.splice(index, 1)
    },
    //打开图片管理器
    openManage(index) {
      this.pickIndex = index
      this.imgshow = true
    },
    //选择图片
    pickimg(e) {
      let item = this.plugins_data.banner[this.pickIndex]
      item.images = e.imgobjs[0].qiniu_url
      this.$set(this.plugins_data.banner, item, this.pickIndex)
    },
  }
};
</script>

<style>
#xskswiper .el-input.is-disabled .el-input__inner {
  color: #333 !important;
}
</style>
<style lang="less" scoped>
@import "../../style/pluginsCommon.css";

.listbox {
  .list {
    padding: 10px;
    background: #f4f6f8;
    border: 1px solid #e9edef;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
    .icon {
      flex: 0 0 24px;
      margin-right: 10px;
      font-size: 24px;
    }
    .imagebox {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      margin-right: 10px;
      .image {
        width: 100%;
        height: 100%;
      }
      .addbox {
        width: 60px;
        height: 60px;
        border: 1px solid #cccccc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .addicon {
          font-size: 20px;
          color: #1989fa;
        }
        .addtext {
          margin-top: 4px;
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .right {
      flex: 1;
      .addlink {
        height: 32px;
        line-height: 32px;
        border: 1px solid #dcdee2;
        color: #409eff;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          border-color: #409eff;
        }
      }
      .tips {
        color: #999999;
        font-size: 12px;
      }
      .changebtn {
        color: #409eff;
        cursor: pointer;
        font-size: 13px;
      }
      .linkIcon {
        color: #409eff;
        font-size: 16px;
      }
    }
    .delicon {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: -10px;
      top: -10px;
      display: none;
      cursor: pointer;
    }
    &:hover {
      .delicon {
        display: block;
      }
    }
  }
}

.addbtn {
  line-height: 32px;
  text-align: center;
  border: 1px solid #dcdee2;
  color: #409eff;
  margin-bottom: 20px;
  &:hover {
    border-color: #409eff;
  }
}
</style>
