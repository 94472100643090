import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/components/mainbox.vue'
import adminindex from '@/views/index/index.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login/login.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/apply/login',
		name: 'applyLogin',
		component: () => import('@/views/registerWechatMp/login.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/adminlogin',
		name: 'AdminLogin',
		component: () => import('@/views/adminlogin/adminlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/agreement',
		name: 'agreement',
		component: () => import('@/views/agreement/agreement.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/apps',
		name: 'Apps',
		component: () => import('@/views/apps/apps.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/userlogin',
		name: 'userlogin',
		component: () => import('@/views/userlogin/userlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/cleanlogin',
		name: 'cleanlogin',
		component: () => import('@/views/userlogin/cleanlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/tescologin',
		name: 'tescologin',
		component: () => import('@/views/userlogin/tescologin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/sharinglogin',
		name: 'sharinglogin',
		component: () => import('@/views/userlogin/sharinglogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/foodlogin',
		name: 'foodlogin',
		component: () => import('@/views/userlogin/foodlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/recruitlogin',
		name: 'recruitlogin',
		component: () => import('@/views/userlogin/recruitlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/recruitlogins',
		name: 'recruitlogins',
		component: () => import('@/views/userlogin/recruitlogins.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/easylogin',
		name: 'easylogin',
		component: () => import('@/views/userlogin/easylogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/servicelogin',
		name: 'servicelogin',
		component: () => import('@/views/userlogin/servicelogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/parklogins',
		name: 'parklogins',
		component: () => import('@/views/userlogin/parklogins.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/shoplogin',
		name: 'shoplogin',
		component: () => import('@/views/userlogin/shoplogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/shopMlogin',
		name: 'shopMlogin',
		component: () => import('@/views/userlogin/shopMlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/servicelogin',
		name: 'servicelogin',
		component: () => import('@/views/apps/qifayi/service/servicelogin.vue'),
		meta: {
			layout: false
		}
	},
	// carlogin
	{
		path: '/retaillogin',
		name: 'retaillogin',
		component: () => import('@/views/userlogin/retaillogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/carlogin',
		name: 'carlogin',
		component: () => import('@/views/userlogin/carlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/carRowLogin',
		name: 'carRowLogin',
		component: () => import('@/views/userlogin/carRowLogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		path: '/orangelogin',
		name: 'orangelogin',
		component: () => import('@/views/userlogin/orangelogin.vue'),
		meta: {
			layout: false
		}
	},
	{

		path: '/psychologicalLogin',
		name: 'psychologicalLogin',
		component: () => import('@/views/userlogin/psychologicalLogin.vue'),
		meta: {
			layout: false
		}
	},
	{
		
		path: '/gallerylogin',
		name: 'gallerylogin',
		component: () => import('@/views/userlogin/gallerylogin.vue'),
		meta: {
			layout: false
		}
	},
	{
	
		path: '/agentlogin',
		name: 'agentlogin',
		component: () => import('@/views/userlogin/agentlogin.vue'),
		meta: {
			layout: false
		}
	},
	{
	
		path: '/yygoods',
		name: 'yygoods',
		component: () => import('@/views/apps/automall/finance/includeList.vue'),
		meta: {
			layout: false
		}
	}
	// {
	// 	path: '/payapps',
	// 	name: 'payapps',
	// 	component: () => import('@/views/payapps/payapps.vue'),
	// 	meta:{
	// 		layout:false 
	// 	}
	// },
]

const createRouter = () => new VueRouter({
	mode: 'hash',
	routes
})

const router = createRouter()

//写一个重置路由的方法，切换用户后，或者退出时清除动态加载的路由
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

export default router
