<template>
	<div class="plugins">
		<div>
			<div class="headsty">组件样式</div>
      <div class="box">
        <div class="label">显示类型:</div>
        <el-radio-group v-model="plugins_data.cofig.showType" size="small">
          <el-radio-button :label="1">普通</el-radio-button>
          <el-radio-button :label="2">左右滑动</el-radio-button>
        </el-radio-group>
      </div>
			<div class="box">
				<div class="label">单行数量:</div>
                <el-radio-group v-model="plugins_data.cofig.number" @change="radioChange" size="small">
                    <el-radio :label="1">1个</el-radio>
                    <el-radio :label="2">2个</el-radio>
                    <el-radio :label="3">3个</el-radio>
                    <el-radio :label="4">4个</el-radio>
                </el-radio-group>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容</div>
			<div class="listbox">
				<draggable v-model="plugins_data.menus" :options="{ animation: 200 }">
					<div class="list" v-for="(item,index) in plugins_data.menus" :key="index">
						<i class="el-icon-error delicon" @click="delMenus(index)" v-if="plugins_data.menus.length>1"></i>
						<div class="left">
							<i class="el-icon-s-unfold"></i>
						</div>
						<div class="right">
							<div class="box flexstart">
								<div class="label">按钮图片:</div>
								<div class="uploadbox">
									<div class="imgbox" @click="openManage(index)">
										<el-image class="image" v-if="item.images" :src="item.images" fit="cover"></el-image>
										<i v-else class="el-icon-plus"></i>
									</div>
									<div class="tips" v-if="plugins_data.cofig.number==1">建议图片尺寸:600x300,高度跟随第一张图片的高度变化</div>
									<div class="tips" v-else>建议图片尺寸:400x400,高度跟随第一张图片的高度变化</div>
								</div>
							</div>
							<div class="box">
								<div class="label">上标题:</div>
								<el-input v-model="item.topTitle" placeholder="请输入按钮文字" maxlength="10" show-word-limit></el-input>
							</div>
							<div class="box">
								<div class="label">下标题:</div>
								<el-input v-model="item.bottomTitle" placeholder="请输入按钮文字"  show-word-limit></el-input>
							</div>
							<div class="box">
								<div class="label">链接:</div>
								<div class="linkbox">
									<el-input v-if="item.link" v-model="item.link.data.name" disabled style="color: #333;!important">
									    <div slot="prepend"><i class="el-icon-link linkIcon"></i></div>
									    <div slot="append"><span class="changebtn" @click="openLink(index,item.link)">修改</span></div>
									</el-input>
									<div class="addlink" v-else @click="openLink(index)"><i class="el-icon-link"></i> 选择链接</div>
								</div>
							</div>
						</div>
					</div>
				</draggable>
				<div class="addbtn" @click="addmenus">+添加按钮</div>
			</div>
		</div>
		
		<div>
			<div class="headsty">内容样式</div>
            <div class="box">
                <div class="label">上标题对齐:</div>
                <el-radio-group v-model="plugins_data.style.topTitleAlign" size="small">
                    <el-radio-button label="left">居左</el-radio-button>
                    <el-radio-button label="center">居中</el-radio-button>
                    <el-radio-button label="right">居右</el-radio-button>
                </el-radio-group>
            </div>
            <div class="box">
                <div class="label">下标题对齐:</div>
                <el-radio-group v-model="plugins_data.style.bottomTitleAlign" size="small">
                    <el-radio-button label="left">居左</el-radio-button>
                    <el-radio-button label="center">居中</el-radio-button>
                    <el-radio-button label="right">居右</el-radio-button>
                </el-radio-group>
            </div>
			<div class="box">
				<div class="label">上标题颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.topTitleColor"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">下标题颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.bottomTitleColor"></el-color-picker>
				<el-input v-model="plugins_data.style.color" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.color = '#333333'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">背景颜色:</div>
				<el-color-picker style="margin-right: 20px;" size="small" v-model="plugins_data.style.background"></el-color-picker>
				<el-input v-model="plugins_data.style.background" style="width: 100px;margin-right: 6px;"></el-input>
				<el-button plain size="small" @click="plugins_data.style.background = '#FFFFFF'">重置</el-button>
			</div>
			<div class="box">
				<div class="label">上边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_top" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_top">
					 <template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_bottom" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">左右边距:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.padding_left" :max="50"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.padding_left">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">上边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_top" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_top">
					<template slot="append">PX</template>
				</el-input>
			</div>
			<div class="box">
				<div class="label">下边角:</div>
				<el-slider class="sliderstyle" v-model="plugins_data.style.border_radius_bottom" :max="40"></el-slider>
				<el-input class="styleinput" type="number" v-model.number="plugins_data.style.border_radius_bottom">
					<template slot="append">PX</template>
				</el-input>
			</div>
		</div>
	
		<chooselink :chooseShow.sync="chooseShow" @confirm="confirm"></chooselink>
		<xsk-manage :managehide.sync="imgshow" is_rest @confirm="pickimg"></xsk-manage>
	</div>
</template>

<script>
import draggable from 'vuedraggable';	
import chooselink from '../../common/chooselink.vue'
export default {
	name: 'meuns',
	components:{draggable,chooselink},
	data() {
		return {
			imgshow:false,
			pickIndex:-1,
			chooseShow:false,
			index:'',
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	created() {
		
	},
	methods:{
		openLink(index,item){
			this.index = index
			this.chooseShow = true
		},
		confirm(e){
			let item = this.plugins_data.menus[this.index]
			item.link = e
				// 不提交自定义页面的content  thunmbnail
				if(item.link.type=="diypage"){
					item.link.data.content=null
					item.link.data.thumbnail=null
				}
			this.$set(this.plugins_data.menus,this.index,item)
		},
		//选择个数
		radioChange(e){
			if(this.plugins_data.menus.length<e){
				this.plugins_data.menus.push({
					url:'',
					text:'按钮文字'+(this.plugins_data.menus.length+1),
					link:null,
				})
				this.radioChange(e)
			}else{
				return
			}
		},
		//添加按钮
		addmenus(){
			this.plugins_data.menus.push({
				url:'',
				text:'按钮文字'+(this.plugins_data.menus.length+1),
				link:null,
			})
		},
		//删除按钮
		delMenus(index){
			this.plugins_data.menus.splice(index,1)
		},
		//打开图片管理器
		openManage(index){
			this.pickIndex = index
			this.imgshow = true
		},
		//选择图片
		pickimg(e){
			let item = this.plugins_data.menus[this.pickIndex]
			item.images = e.imgobjs[0].qiniu_url
			this.$set(this.plugins_data.menus,this.pickIndex,item)
		},
	}
};
</script>

<style scoped lang="less">
@import "../../style/pluginsCommon.css";

.shapebox{
	display: flex;
	.radiobox{
		padding: 4px 20px;
		border: 1px solid #CCCCCC;
		cursor: pointer;
		&:first-child{
			border-right: none;
		}
		.menustyle{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			background: #939799;
			color: #FFFFFF;
			font-size: 14px;
		}
		.cir{
			border-radius: 50%;
		}
		.squ{
			border-radius: 4px;
		}
	}
	.active{
		border-right: 1px solid #1989fa !important;
		border: 1px solid #1989fa;
		.menustyle{
			background: #1989fa !important;
		}
	}
}

.listbox{
	margin: 10px 0 20px;
	.list{
		padding: 15px;
		background: #F4F6F8;
		border: 1px solid #E9EDEF;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		position: relative;
		&:last-child{
			margin-bottom: 0;
		}
		.delicon{
			font-size: 20px;
			color: rgba(0,0,0,.3);
			position: absolute;
			right: -10px;
			top: -10px;
			display: none;
			cursor: pointer;
		}
		&:hover{
			.delicon{
				display: block;
			}
		}
		.left{
			flex: 0 0 30px;
			font-size: 20px;
			color: #b8b9bd;
		}
		.right{
			flex: 1;
			.box{
				&:last-child{
					margin-bottom: 0;
				}
				.label{
					flex: 0 0 70px;
					width: 70px;
				}
			}
			.flexstart{
				align-items: flex-start;
			}
			.uploadbox{
				.imgbox{
					width: 40px;
					height: 40px;
					border: 1px solid #E9EDEF;
					display: flex;
					align-items: center;
					justify-content: center;
					font-weight: bold;
					font-size: 20px;
					color: #1989fa;
					cursor: pointer;
					background: #FFFFFF;
					.image{
						width: 38px;
						height: 38px;
					}
				}
				.tips{
					margin-top: 6px;
					color: #939799;
					font-size: 12px;
				}
			}
			.linkbox{
				.addlink{
					height: 32px;
					line-height: 32px;
					border: 1px solid #dcdee2;
					color: #409eff;
					padding: 0 10px;
					box-sizing: border-box;
					border-radius: 4px;
					cursor: pointer;
					background: #FFFFFF;
					&:hover{
						border-color: #409eff;
					}
				}
				.tips{
					color: #999999;
					font-size: 12px;
				}
				.changebtn{
					color: #409eff;
					cursor: pointer;
				}
				.linkIcon{
					color: #409eff;
					font-size: 16px;
				}
			}
		}
	}
}

.addbtn{
		line-height: 32px;
		text-align: center;
		border: 1px solid #dcdee2;
		color: #409eff;
		margin-bottom: 20px;
		&:hover{
			border-color: #409eff;
		}
	}
</style>
