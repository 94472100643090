<template>
  <div class="rigbtbox">
    <div class="headbox">
      <div class="title" v-if="!userinfo && isApply">
        <span>快速创建小程序</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'api'">
        <span v-if="userinfo.type == 0">智能物联saas系统</span>
        <span v-else>{{ userinfo.shop_name || "店铺名称" }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'periphery_admin_user'">
        <span v-if="userinfo.type == 1">{{
          userinfo.site_name || "站点名称"
        }}</span>
        <span v-if="userinfo.type == 2">{{ userinfo.name || "商家名称" }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'hous_keep_admin_user'">
        <span v-if="userinfo.type == 1">{{
          userinfo.company_name || "代理名称"
        }}</span>
        <span v-if="userinfo.type == 2">{{
          userinfo.company_name || "商家名称"
        }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'tesco_seller_admin'">
        <span>{{ userinfo.company_name || "商家名称" }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'park_admin_user'">
        <span>{{ userinfo.username || "管理员名称" }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'store_admin_user'">
        <span>{{ userinfo.name || "商家后台" }}</span>
      </div>

      <div class="title" v-if="userinfo.guard_name == 'retail_admin_user'">
        <span>{{ userinfo.store_name || "门店后台" }}</span>
      </div>

      <div class="phone">服务热线：028-65211992</div>
      <div
        class="endtime"
        v-if="userinfo.guard_name == 'api' && userinfo.shop_term"
      >
        到期时间：{{ changetime(userinfo.shop_term.expire_time) }}
      </div>
      <div
        class="endtime"
        v-if="userinfo.guard_name == 'api' && !userinfo.shop_term"
      >
        智能物联总后台控制
      </div>
      <div
        class="paybtn"
        v-if="userinfo.guard_name == 'api' && userinfo.type != 0"
      >
        <el-button type="primary" style="width: 100%" @click="openpay"
          >立即续费</el-button
        >
      </div>
    </div>

    <div class="msgbox">
      <div class="title">公告 <span @click="tonotice(false)">更多</span></div>

      <div
        class="msgs"
        v-for="(item, index) in noticelist"
        :key="index"
        @click="tonotice(item.id)"
      >
        <div class="text">{{ item.title }}</div>
        <div class="time">{{ getmonth(item.created_at) }}</div>
      </div>
    </div>

    <!-- <div class="msgbox">
			<div class="title">优质案例 <span @click="tocase(false)">更多</span></div>
			
			<div class="msgs" v-for="(item,index) in caselist" :key="index" @click="tocase(item.id)">
				<div class="text">{{item.title}}</div>
				<div class="time">{{getmonth(item.created_at)}}</div>
			</div>
		</div>
		
		<div class="msgbox">
			<div class="title">使用指南 <span @click="tohelp(false)">更多</span></div>
			
			<div class="msgs" v-for="(item,index) in helplist" :key="index" @click="tohelp(item.id)">
				<div class="text">{{item.title}}</div>
				<div class="time">{{getmonth(item.created_at)}}</div>
			</div>
		</div> -->
    <div class="msgbox">
      <div class="title">微信公众号</div>

      <el-image
        class="img"
        :src="require('assets/images/img-gzh.jpg')"
        fit="cover"
      ></el-image>
    </div>

    <div class="msgbox">
      <div class="title">客服人员</div>

      <el-image
        class="img"
        :src="require('assets/images/img-kf.jpg')"
        fit="cover"
      ></el-image>
    </div>

    <el-dialog
      id="xskrightbox"
      title=""
      :visible.sync="payboxshow"
      :append-to-body="true"
      :show-close="false"
      width="800px"
    >
      <div class="paybox">
        <div class="box active">
          <div class="title">应用名称</div>
          <div class="price"><span>￥</span>2999<span>/年</span></div>
          <div class="btn">立即订购</div>
        </div>
        <div class="box">
          <div class="title">应用名称</div>
          <div class="price"><span>￥</span>4999<span>/3年</span></div>
          <div class="btn">立即订购</div>
        </div>
        <div class="box">
          <div class="title">应用名称</div>
          <div class="price"><span>￥</span>6999<span>/5年</span></div>
          <div class="btn">立即订购</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "asidenav",
  data() {
    return {
      userinfo: "",
      noticelist: [],
      caselist: [],
      helplist: [],
      payboxshow: false,
      isApply: "",
    };
  },
  created() {
    this.isApply = sessionStorage.getItem("isApply");
    this.getnoticelist();
    this.getcaselistlist();
    this.gethelplist();
    if (!sessionStorage.getItem("userinfo")) {
      return;
    }
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
  },
  methods: {
    openpay() {
      // this.payboxshow = true
      return;
      this.$router.push({ path: "/payapps" });
    },
    changetime(time) {
      let year = new Date(time).getFullYear();
      let month = new Date(time).getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = new Date(time).getDate();
      if (day < 10) {
        day = "0" + day;
      }

      return year + "年" + month + "月" + day + "日";
    },
    getmonth(time) {
      let month = new Date(time).getMonth() + 1;
      let day = new Date(time).getDate();
      return (
        (month < 10 ? "0" + month : month) + "/" + (day < 10 ? "0" + day : day)
      );
    },
    getnoticelist() {
      this.axios
        .get("/api/notice/index", { params: { page: 1, limit: 6 } })
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            this.noticelist = res.data.data;
          }
        });
    },
    getcaselistlist() {
      this.axios
        .get("/api/example/index", { params: { page: 1, limit: 6 } })
        .then((res) => {
          if (res.code == 200) {
            this.caselist = res.data.data;
          }
        });
    },
    gethelplist() {
      this.axios
        .get("/api/guide/index", { params: { page: 1, limit: 6 } })
        .then((res) => {
          if (res.code == 200) {
            this.helplist = res.data.data;
          }
        });
    },
    tohelp(id) {
      if (id) {
        if (this.$route.query.id == id) {
          return;
        }
        this.$router.push({ path: "/help/list", query: { id: id } });
      } else {
        if (this.$route.path == "/help/list") {
          return;
        }
        this.$router.push({ path: "/help/list" });
      }
    },
    tonotice(id) {
      if (id) {
        if (this.$route.query.id == id) {
          return;
        }
        this.$router.push({ path: "/notice/detail", query: { id: id } });
      } else {
        if (this.$route.path == "/notice") {
          return;
        }
        this.$router.push({ path: "/notice" });
      }
    },
    tocase(id) {
      if (id) {
        if (this.$route.query.id == id) {
          return;
        }
        this.$router.push({ path: "/case/detail", query: { id: id } });
      } else {
        if (this.$route.path == "/case") {
          return;
        }
        this.$router.push({ path: "/case" });
      }
    },
  },
};
</script>

<style>
#xskrightbox .el-dialog__header {
  display: none;
}

#xskrightbox .el-dialog__body {
  padding: 30px;
  border-radius: 10px;
}
</style>

<style lang="less" scoped>
.rigbtbox {
  width: 200px;
  height: calc(100vh - 110px);
  // overflow-y: scroll;
  // background: #FFFFFF;
  // padding:20px;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headbox {
    background: #ffffff;
    padding: 15px 10px;
    .title {
      font-size: 15px;
      line-height: 16px;
      font-weight: bold;
    }
    .phone {
      margin-top: 20px;
      font-size: 13px;
    }
    .endtime {
      font-size: 12px;
      color: #999999;
      margin-top: 20px;
      span {
        float: right;
        color: #1e92ff;
      }
    }
    .paybtn {
      margin-top: 20px;
      text-align: center;
    }
  }
  .msgbox {
    margin-top: 10px;
    padding: 15px 10px;
    background: #ffffff;
    .title {
      font-size: 14px;
      font-weight: bold;
      span {
        color: #9797a1;
        font-weight: normal;
        float: right;
        cursor: pointer;
        color: #1e92ff;
      }
    }
    .msgs {
      font-size: 12px;
      cursor: pointer;
      margin-top: 10px;
      .text {
        width: 140px;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:hover {
          color: #1e92ff;
        }
      }
      .time {
        width: 40px;
        display: inline-block;
        vertical-align: top;
        text-align: right;
      }
    }
    .img {
      margin: 10px auto 0;
      display: block;
      width: 160px;
      height: 160px;
    }
  }
}

.paybox {
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box {
    width: 200px;
    height: 260px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .title {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    .price {
      margin-top: 20px;
      color: #1e92ff;
      font-weight: bold;
      font-size: 38px;
      text-align: center;
      span {
        font-size: 20px;
        font-weight: normal;
      }
    }
    .btn {
      margin-top: 20px;
      width: 120px;
      line-height: 42px;
      text-align: center;
      background: #1e92ff;
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  .active {
    border-color: #1e92ff;
  }
}
</style>
