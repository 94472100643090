import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import ImStatus from './modules/ImStatus';

export default new Vuex.Store({
  state: {
	  is_apply:0,
	  applyLogin:1,
	  userlogin:1,
	  asideWidth:0,
	  routeadds:0,
	  pluginroute:0,
	  //缓存的视图
	  includeAlive: [],
	  
  },
  getters: {
	  getIncludeAlive(state) {
		  return state.includeAlive;
	  }
  },
  mutations: {
    addIncludeAlive(state, v) {
      v && !state.includeAlive.includes(v) && state.includeAlive.push(v)
    },
	  //切换登陆、注册
	  changeIsApply(state,is_apply){
		state.is_apply = is_apply  
	  },
	  changeuserlogin(state,userlogin){
		  state.userlogin = userlogin
	  },
	  //切换登陆、注册
	  changeApplyLogin(state,applyLogin){
		  state.applyLogin = applyLogin
	  },
	  //二级菜单
	  changeasideWidth(state,asideWidth){
		  state.asideWidth = asideWidth
	  },
	  //路由是否加载
	  routestatus(state,routeadds){
		  state.routeadds = routeadds
	  },
	  //插件路由
	  pluginrouteadd(state,routeid){
		  state.pluginroute = routeid
	  }
  },
  actions: {
  },
  modules: {
	  ImStatus
  }
})
