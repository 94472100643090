<template>
  <div class="menu" ref="menuref">
    <div class="list">
      <div :class="{logbox:loading}" class="logImage" v-if="!userinfo || !userinfo.admin_log"></div>
      <div :class="{logbox:loading}" class="logImage" v-else :style="'background-image:url(' + (userinfo && userinfo.admin_log ? userinfo.admin_log : '../../assets/icon/LOGO-white.png') + ')'"></div>
      <div class="menu-item" v-for="(item, index) in meun" :key="index" :class="{ active: item.meta.router_name == $route.meta.router_name }" @click="meunlink(item)">
        <!-- <i :class="item.meta.icon" style="color: #FFFFFF;margin-right: 3px;font-size: 18px;position: relative;top: 2px;"></i> -->
        <!-- <i class="iconfont" :class="item.meta.icon" style="color: #FFFFFF;margin-right: 3px;font-size: 14px;"></i> -->

        <!-- <svg-icon icon-class="yingyong" /> -->
        <div class="activeBox">
          <svg-icon :icon-class="item.meta.icon" style="margin-right: 3px;font-size: 16px;position: relative;top: 2px;color: #7a8599;"></svg-icon>
          {{ item.meta.title }}
        </div>
      </div>
    </div>
    <div class="morelist" v-if="list.length > 0">
      <!-- :style="{height:(childindex==i?'auto':'56px')}" -->
      <div class="text" v-for="(nav, i) in list" :key="i" @click="navpath(nav, i)">
        <div class="titles" :class="{ navactive: nav.path == $route.path, towmenu: !nav.path }">
          <span>{{ nav.meta.title }}</span>
          <!-- &&calclayout(children) -->
          <span class="icons" :class="{ transition: childindex == i }" v-if="nav.children && nav.children.length > 0">
            <i class="el-icon-arrow-right"></i>
          </span>
          <div class="activeBack">{{ nav.meta.title }}</div>
        </div>

        <el-collapse-transition>
          <div v-show="childindex == i">
            <template v-for="(children, children_i) in nav.children">
              <div class="childtext" :class="{ navactive: children.path == $route.path }" v-if="children.meta.layout == 1" :key="children_i" @click.stop="threepath(children)">
                <span>{{ children.meta.title }}</span>
                <div class="activeBack">{{ children.meta.title }}</div>
              </div>
            </template>
          </div>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>

<script>
import { resetRouter } from "@/router/index.js";
import store from "@/store/index.js";
import router from "@/router/index.js";
import { changeroute } from "@/router/routerPermission.js";
export default {
  name: "asidenav",
  data() {
    return {
      meun: [],
      list: [],
      childindex: 0,
      userinfo: "",
      loading: true,
    };
  },
  created() {
    let routes = JSON.parse(sessionStorage.getItem("routestext"));
    routes.forEach((item) => {
      if (item.meta.layout == 1) {
        this.meun.push(item);
      }
    });
    this.children_route();
  },
  mounted() {
    this.getInfo();
  },
  watch: {
    $route: function (newflag, oldflag) {
      this.children_route();
    },
  },
  methods: {
    //获取信息
    getInfo() {
      let info = JSON.parse(sessionStorage.getItem("userinfo"));
      if (info && info.roles && info.roles.length > 0) {
        let guard_name = "";
        if (info.roles[0].id == 59) {
          guard_name = "api";
        }
        if (info.roles[0].id == 22) {
          guard_name = "store_admin_user";
        }
        if (!guard_name) {
          return
        }
        this.loading = false
        this.axios.get("/api/me", { params: { guard_name: guard_name } }).then((res) => {
          this.loading = true
          if (res.code == 200) {
            this.userinfo = JSON.parse(JSON.stringify(res.data));
            sessionStorage.setItem("userinfo", JSON.stringify(res.data));
            if (res.data.unitid == 2) {
              sessionStorage.setItem("role_id", res.data.roles[0].id);
            }
          }
        });
      }
    },
    calclayout(arr) {
      let count = 0;
      arr.forEach((item) => {
        if (item.layout == 1) {
          count++;
        }
      });
      if (count > 1) {
        return true;
      } else {
        return false;
      }
    },
    //监控子路由
    children_route() {
      let that = this;
      let routes = JSON.parse(sessionStorage.getItem("routestext"));
      if (this.$route.matched.length > 1) {
        routes.forEach((item) => {
          if (item.meta.title == that.$route.matched[0].meta.title) {
            that.list = [];
            item.children.forEach((child) => {
              if (child.meta.layout == 1) {
                that.list.push(child);
              }
            });
          }
        });
        if (that.list.length > 0) {
          this.$store.commit("changeasideWidth", 50);
        } else {
          this.$store.commit("changeasideWidth", 0);
        }
      } else {
        this.list = [];
        this.$store.commit("changeasideWidth", 0);
      }
    },
    //跳转
    meunlink(item) {
      console.log(item, "一级菜单跳转");
      if (item.path) {
        if (item.path != this.$route.path) {
          let userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
          let unitid = userinfo.unitid || "";

          if (item.path == "/applist") {
            let routes = JSON.parse(sessionStorage.getItem("routes"));
            routes.some((item, index) => {
              if (item.path == "/applist" && item.component == "Layout") {
                item._child = [];
                item.component = "applist/applist.vue";
                resetRouter();
                router.addRoutes(changeroute(routes));
                sessionStorage.setItem("routes", JSON.stringify(routes));
              }
            });
          }

          if (item.path == "/voteapps") {
            let routes = JSON.parse(sessionStorage.getItem("routes"));
            routes.some((item, index) => {
              if (item.path == "/voteapps" && item.component == "Layout") {
                item._child = [];
                item.component = "apps/vote/apps/apps.vue";
                resetRouter();
                router.addRoutes(changeroute(routes));
                sessionStorage.setItem("routes", JSON.stringify(routes));
              }
            });
          }

          if (item.path == "/channel" && unitid == 3) {
            let routes = JSON.parse(sessionStorage.getItem("routes"));
            routes.some((item, index) => {
              if (item.path == "/channel" && item.component == "Layout") {
                item._child = [];
                item.component = "apps/vote/channel/channel.vue";
                resetRouter();
                router.addRoutes(changeroute(routes));
                sessionStorage.setItem("routes", JSON.stringify(routes));
              }
            });
          }

          //渠道一级菜单 清空动态添加
          if (item.path == "/channel" && unitid != 3) {
            let routes = JSON.parse(sessionStorage.getItem("routes"));
            routes.some((item, index) => {
              if (item.path == "/channel" && item.component == "Layout") {
                item._child = [];
                item.component = "apps/channel/channel.vue";
                resetRouter();
                router.addRoutes(changeroute(routes));
                sessionStorage.setItem("routes", JSON.stringify(routes));
              }
            });
          }

          //应用一级菜单 清空动态添加
          if (item.path == "/application") {
            let routes = JSON.parse(sessionStorage.getItem("routes"));
            routes.some((item, index) => {
              if (item.path == "/application" && item.component == "Layout") {
                item.component = "apps/clean/apps/apps.vue";

                // if(unitid==4){
                // 	item.component = 'apps/shopping/apps/apps.vue'
                // }

                resetRouter();
                router.addRoutes(changeroute(routes));
                sessionStorage.setItem("routes", JSON.stringify(routes));
              }
            });
          }

          this.$router.push({ path: item.path });
          this.$store.commit("changeasideWidth", 0);
        }
      } else {
        if (item.children[0].path != this.$route.path) {
          for (let i = 0; i < item.children.length; i++) {
            let child = item.children[i];
            if (child.meta.layout == 1) {
              if (child.children) {
                this.childindex = 0;
                if (child.children[0].path == this.$route.path) {
                  return;
                }

                if (child.children[0].meta.props) {
                  this.$router.push({
                    path: child.children[0].path,
                    query: { props: child.children[0].meta.props },
                  });
                  return;
                } else {
                  this.$router.push({ path: child.children[0].path });
                  return;
                }
              } else {
                this.childindex = -1;
                if (child.meta.props) {
                  this.$router.push({
                    path: child.path,
                    query: { props: child.meta.props },
                  });
                } else {
                  this.$router.push({ path: child.path });
                }
                this.$store.commit("changeasideWidth", 50);
                return;
              }
            }
          }
          this.$store.commit("changeasideWidth", 0);
        }
      }
    },
    //二级菜单跳转
    navpath(item, index) {
      if (item.children) {
        if (this.childindex == index) {
          this.childindex = -1;
          // return
        } else {
          this.childindex = index;
        }
        // if(item.children[0].path!=this.$route.path){
        // 	if(item.children[0].meta.props){
        // 		this.$router.push({path: item.children[0].path,query:{props:item.children[0].meta.props}})
        // 	}else{
        // 		this.$router.push({path: item.children[0].path})
        // 	}
        // }
      } else {
        this.childindex = -1;
        if (item.path && item.path != this.$route.path) {
          if (item.meta.props) {
            this.$router.push({
              path: item.path,
              query: { props: item.meta.props },
            });
          } else {
            this.$router.push({ path: item.path });
          }
        }
      }
    },
    //三级菜单跳转
    threepath(item) {
      if (item.path && item.path != this.$route.path) {
        if (item.meta.props) {
          this.$router.push({
            path: item.path,
            query: { props: item.meta.props },
          });
        } else {
          this.$router.push({ path: item.path });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
:root {
  font-size: 10px;
  --radius-size: 5px;
  --acticity-color: #fff;
}

.menu {
  position: relative;
  height: 100vh;
  z-index: 999;
  .list {
    display: inline-block;
    vertical-align: top;
    min-width: 100px;
    background: #ebf1ff;
    height: 100vh;
    overflow-y: auto;
    padding-top: 10px;
    .logImage {
      width: 70px;
      height: 26px;
      margin: 0 auto 13px;
    }
    .logbox {
      width: 70px;
      height: 26px;
      margin: 0 auto 13px;
      background-image: url(../../assets/icon/LOGO-white.png);
      background-size: 100% 100%;
    }
    .menu-item {
      width: 100px;
      line-height: 50px;
      font-size: 14px;
      font-weight: bold;
      color: #7a8599;
      cursor: pointer;
      text-align: center;
      &.active {
        .activeBox {
          position: relative;

          border-radius: 10px 0 0 10px;
          background: #ffffff;
          color: #3d7fff;
          margin-left: 14px;
          padding-left: 10px;
          text-align: left;
          .svg-icon {
            color: #3d7fff !important;
          }

          // &::before,
          // &::after {
          //   content: "";
          //   display: block;
          //   width: 10px;
          //   height: 10px;
          //   position: absolute;
          //   right: 0;
          //   background: radial-gradient(
          //     5px at 5px 0px,
          //     transparent 5px,
          //     #fff 5px
          //   );
          // }

          // &::before {
          //   top: -10px;
          // }

          // &::after {
          //   bottom: -10px;
          // }
        }
      }
    }
  }

  .morelist {
    display: inline-block;
    vertical-align: top;
    // position: absolute;
    padding-top: 60px;
    width: 130px;
    height: 100vh;
    overflow-y: auto;
    background: #ffffff;
    // left: 100px;
    // top: 50px;
    position: relative;
    z-index: 999;
    cursor: pointer;
    .text {
      color: #000000;
      font-size: 14px;
      font-weight: normal;
      line-height: 50px;
      height: auto;
      overflow: hidden;
      user-select: none;
    }
    .titles {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icons {
        margin-left: 4px;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        transition: all 0.3s;
      }
      .transition {
        transform: rotate(90deg);
      }
    }
    .childtext {
      padding: 0 30px;
      font-size: 14px;
      color: #666;
      background: #fff;
      &.navactive {
        .activeBack {
          padding: 0 25px;
        }
      }
    }
    .towmenu {
      // font-weight: bold;
      div {
        text-align: left !important;
        font-weight: normal;
        color: #000000;
      }
    }
    .activeBack {
      display: none;
    }
    .navactive {
      height: 50px;
      line-height: 50px;
      position: relative;
      font-weight: bold;
      span {
        display: none;
      }
      .activeBack {
        padding: 0 15px;
        display: block;
        line-height: 48px;
        border-radius: 8px;
        color: #2d8cf0 !important;
        background: rgba(45, 140, 240, 0.1) !important;
        position: absolute;
        top: 2px;
        left: 5px;
        right: 5px;
        bottom: 2px;
        z-index: 0;
      }
    }
  }
}
</style>
