<template>
  <div class="headbox">
    <div class="welcome">
      {{ shop_name }}
      <span>{{ time_text }}</span>
      欢迎使用
      {{ isApply ? "认证小程序快速创建系统" : userinfo.settings ? userinfo.settings.plat_name + "管理后台" : "智能物联客户服务器控制台" }}!
    </div>
    <div class="account">
      <el-image class="userlogo" :src="usericon" fit="contain"></el-image>
      <span>账号：{{ userinfo.phone }}</span>
    </div>
    <el-dropdown trigger="click" class="menu headernava" @command="handleCommand">
      <div class="el-dropdown-link">
        <el-image class="img" :src="menuicon" fit="contain"></el-image>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="changeuser" v-if="userinfo && userinfo.guard_name == 'api'"><i class="el-icon-set-up el-icon--left"></i>修改账号</el-dropdown-item>
        <el-dropdown-item command="changepwd" v-if="userinfo && userinfo.guard_name == 'api'"><i class="el-icon-lock el-icon--left"></i>修改密码</el-dropdown-item>
        <el-dropdown-item command="loginout"><i class="el-icon-switch-button el-icon--left"></i>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import menuicon from "../../assets/icon/icon-menu.png";
import usericon from "../../assets/icon/user-avatar.png";
import { resetRouter } from "@/router/index.js";
import store from "@/store/index.js";
export default {
  name: "headernav",
  data() {
    return {
      time_text: "上午好",
      menuicon: menuicon,
      usericon: usericon,
      userinfo: "",
      isApply: "",
      shopName: "",
      shop_name: "",
    };
  },
  created() {
    this.userinfo = JSON.parse(sessionStorage.getItem("userinfo")) || "";
    this.shop_name = this.userinfo.guard_name != "api" ? (this.userinfo.api_user ? this.userinfo.api_user.shop_name : this.userinfo.name) : this.userinfo.shop_name;
    this.shopName = sessionStorage.getItem("shop_name") || this.userinfo.name;
    this.isApply = sessionStorage.getItem("isApply");
    console.log(this.userinfo)
    console.log(this.shop_name)
    console.log(this.shopName)
    this.gettime();
  },
  methods: {
    //根据时间改变提示语
    gettime() {
      let time = new Date().getHours();
      if (time < 6) {
        this.time_text = "夜深了";
      } else if (time < 12) {
        this.time_text = "早上好";
      } else if (time < 14) {
        this.time_text = "中午好";
      } else if (time < 18) {
        this.time_text = "下午好";
      } else if (time < 23) {
        this.time_text = "晚上好";
      } else {
        this.time_text = "夜深了";
      }
    },
    handleCommand(command) {
      let that = this;

      if (command == "accountInfo") {
        this.$router.push({ path: "/account/accountInfo" });
      } else if (command == "changeuser") {
        this.$router.push({ path: "/account/editaccount" });
      } else if (command == "changepwd") {
        this.$router.push({ path: "/account/editpassword" });
      } else {
        if (sessionStorage.getItem("isApply")) {
          that.$router.replace({ path: "/apply/login" });

          resetRouter();
          this.$store.commit("routestatus", 0);
          this.$store.commit("changeIsApply", 0);
          sessionStorage.removeItem("routestext");
          sessionStorage.removeItem("guardName");
          sessionStorage.removeItem("routes");
          sessionStorage.removeItem("userinfo");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("isApply");
          return;
        }

        let guard_name = JSON.parse(
          sessionStorage.getItem("userinfo")
        ).guard_name;

        let data = {
          guard_name: guard_name,
        };

        this.axios.post("/api/logout", data).then((res) => {
          if (res.code == 200) {
            let userinfo = JSON.parse(sessionStorage.getItem("userinfo"));

            let type = userinfo.type;
            let guard_name = userinfo.guard_name;
            let guard_info = {
              type: type,
            };

            //判断登出到某个后台登录页面
            switch (guard_name) {
              default:
                guard_info.guard_name = "api";
                guard_info.name = userinfo.shop_name;
                if (type == 0) {
                  that.$router.replace({ path: "/adminlogin" });
                } else {
                  that.$router.replace({ path: "/login" });
                }
                break;
              case "periphery_admin_user": //联联周边游分后台 type： 1、站点 2、商家
                guard_info.name = userinfo.user.shop_name;
                guard_info.guard_name = "periphery_admin_user";
                guard_info.shop_id = userinfo.user.shop_id;
                that.$router.replace({
                  path: `/userlogin?name=${userinfo.user.shop_name}&type=${type}&shop_id=${userinfo.shop_id}&guard_name=periphery_admin_user`,
                });
                break;
              case "hous_keep_admin_user": //智慧家政分后台 type： 1、代理 2、商家
                guard_info.name = userinfo.api_user.shop_name;
                guard_info.guard_name = "hous_keep_admin_user";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/cleanlogin?name=${userinfo.api_user.shop_name}&type=${type}&shop_id=${userinfo.shop_id}&guard_name=hous_keep_admin_user`,
                });
                break;
              case "store_admin_user": //商城商家后台
                guard_info.name = userinfo.api_user.shop_name;
                guard_info.guard_name = "store_admin_user";
                guard_info.shop_id = userinfo.shop_id;
                //是否简洁模式
                let clear = sessionStorage.getItem("clear");
                that.$router.replace({
                  path: `/shoplogin?name=${userinfo.api_user.shop_name}&type=${type}&shop_id=${userinfo.shop_id}&guard_name=store_admin_user${clear ? '&clear=1' : ''}`,
                });
                break;
              case "retail_admin_user": //新零售分后台
                guard_info.name = userinfo.api_user.shop_name;
                guard_info.guard_name = "retail_admin_user";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/retaillogin?name=${userinfo.api_user.shop_name}&shop_id=${userinfo.shop_id}&guard_name=retail_admin_user`,
                });
                break;
              case "assessment_admin_user": //世大云心分后台
                guard_info.name = that.shopName;
                guard_info.guard_name = "assessment_admin_user";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/psychologicalLogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=assessment_admin_user`,
                });
                break;
              case "gallery_admin_user": //图库分后台
                guard_info.name = userinfo.api_user.shop_name;
                guard_info.guard_name = "gallery_admin_user";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/gallerylogin?name=${userinfo.api_user.shop_name}&shop_id=${userinfo.shop_id}&guard_name=gallery_admin_user`,
                });
                break;
              case "car_market": //二手车市场后台
                guard_info.name = that.shopName;
                guard_info.guard_name = "car_market";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/carlogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=car_market`,
                });
                break;
              case "car_shop": //二手车车行分后台
                guard_info.name = that.shopName;
                guard_info.guard_name = "car_shop";
                guard_info.shop_id = userinfo.shop_id;
                that.$router.replace({
                  path: `/carRowLogin?name=${that.shopName}&shop_id=${userinfo.shop_id}&guard_name=car_shop`,
                });
                break;
            }

            resetRouter();
            this.$store.commit("routestatus", 0);
            sessionStorage.setItem("guard_info", JSON.stringify(guard_info));
            sessionStorage.removeItem("routestext");
            sessionStorage.removeItem("routes");
            sessionStorage.removeItem("userinfo");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("pluginroute");
            sessionStorage.removeItem("pluginid");
            sessionStorage.removeItem("isApply");
          } else {
            this.$message(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style>
.el-dropdown-menu {
  padding: 0 !important;
}

.el-dropdown-menu__item {
  line-height: 66px !important;
  padding: 0 30px !important;
}
</style>

<style lang="less" scoped>
.headbox {
  background: #b357f5;
  height: 50px;
  background: #ffffff;
  padding: 0 20px;
  box-shadow: 0px 4px 4px -4px rgba(0, 0, 0, 0.2);
  display: flex;
  .welcome {
    display: inline-block;
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    flex: 1;
    span {
      color: #ff881e;
    }
  }
  .account{
    line-height: 50px;
    margin-right: 20px;
    font-weight: bold;
    .userlogo{
      width: 30px;
      height: 30px;
      margin-top: 10px;
    }
    span{
      vertical-align: top;
    }
  }
  .menu {
    display: inline-block;
    float: right;
    height: 50px;
    padding-top: 16px;
    cursor: pointer;

    .img {
      width: 20px;
      height: 17px;
    }
  }
}
</style>
