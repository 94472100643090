<template>
  <div class="launchads" :style="{backgroundImage:'url('+require('assets/images/advs-skeleton.png')+')'}">
    <div class="launchads-mask"
     :style="{background:plugins_data.style.background,opacity:plugins_data.style.opacity/100}">
    </div>
    <div v-if="plugins_data.cofig.type == 1"
    class="close">
        <span>{{plugins_data.cofig.closeTime==0?'关闭':plugins_data.cofig.closeTime+'s'}}</span>
        <span v-if="plugins_data.cofig.closeTime!=0 && plugins_data.cofig.closeButton">关闭</span>
    </div>
    <div
      class="swiper"
      :class="{
        fullScreen: plugins_data.cofig.type == 1,
        boxOne: plugins_data.cofig.type == 2,
        boxTwo: plugins_data.cofig.type == 3,
      }"
    >
    <i class="el-icon-circle-close" v-if="plugins_data.cofig.type == 3"></i>
      <swiper ref="mySwiper" :options="swiperOption" style="z-index: 0">
        <swiper-slide v-for="(item, index) in plugins_data.menus" :key="index">
          <div class="imgbox">
            <!-- <el-image class="img" :src="item.images" fit="cover"></el-image> -->
            <img
              class="img"
              :src="item.images || require('assets/images/img.png')"
              alt=""
            />
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      
    <i class="el-icon-circle-close" v-if="plugins_data.cofig.type == 2"></i>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      swiperOption: {
        autoHeight: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  props: {
    plugins_data: { type: Object },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    console.log(this.plugins_data);
    this.swiper.allowTouchMove = false;
  },
};
</script>

<style lang="scss" scoped>
.launchads {
  height: calc(700px - 114px); 
  background-position: center;
  background-size: cover;
  position: relative;
  .close{
    position: absolute;
    right: 10px;
    top: 10px;
    height: 24px;
    padding: 0 10px;
    line-height: 24px;
    border-radius: 12px;
    color: #fff;
    background: #555;
  }
  .launchads-mask{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .imgbox {
      height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .swiper {
    .swiper-container {
      height: 100%;
        box-shadow: 0 0 4px 1px  #EEEEEE;
    }
  }
  .fullScreen {
    height: 100%;
  }
  .boxOne {
    position: absolute;
    height: 60%;
    width: 60%;
    top: 15%;
    left: 20%;
    .el-icon-circle-close{
        display: block;
        text-align: center;
        font-size: 30px;
        color: #fff;
        margin: 20px auto;
    }
  }
  .boxTwo {
        position: absolute;
        height: 60%;
        width: 60%;
        top: 10%;
        left: 20%;
        .el-icon-circle-close{
            display: block;
            text-align: right;
            font-size: 30px;
            color: #fff;
            margin: 20px auto;
        }
  }
}
</style>