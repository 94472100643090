<template>
  <div class="noticebox" :style="{
	  'background':plugins_data.style.background,
		'margin':plugins_data.style.padding_top/2+'px '+plugins_data.style.padding_left/2+'px '+plugins_data.style.padding_bottom/2+'px',
		'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
		}">
    <div style="display:flex;align-items:center;padding: 5px 10px;">
      <div class="iconbox">
        <el-image style="width: 100%;height: 100%;" :src="plugins_data.data.image" fit="cover"></el-image>
      </div>
      <div class="notice">
        <el-carousel height="20px" direction="vertical" autoplay loop indicator-position="none">
          <el-carousel-item v-for="(item,index) in plugins_data.data.list" :key="index">
            <div :style="{'color':plugins_data.style.color}">{{ item.msg }}</div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'noticeView',
  data() {
    return {
      list: [],
    };
  },
  props: {
    plugins_data: { type: Object }
  },
};
</script>

<style scoped lang="less">
.noticebox {
  overflow: hidden;
  .iconbox {
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .notice {
    flex: 1;
  }
}
</style>
