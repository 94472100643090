<template>
	<div id="businessView" :style="{
		'padding':plugins_data.style.padding_top/2+'px '+plugins_data.style.padding_left/2+'px '+plugins_data.style.padding_bottom/2+'px'
		}">
		<template v-if="list.length==0">
			<div class="cardbox" :style="{
				'background':plugins_data.style.background,
				'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
				}">
				<div class="storebox">
					<el-image
					:class="{'logo':plugins_data.data.storeStyle==1,'logo2':plugins_data.data.storeStyle==2}"
					 :style="{'border-radius':plugins_data.data.logoType==1?'50%':'6px'}"
					  :src="require('assets/images/shopping/business.png')" fit="cover"></el-image>
					<div class="infobox"  :style="plugins_data.data.storeStyle==2?'height:60px':''">
						<div class="name">店铺名称</div>
						<div class="number" v-if="plugins_data.data.showTotal">在售商品999件</div>
					</div>
					<div class="rightbox">
						<div class="btn" v-if="plugins_data.data.showBtn">进店</div>
						<div class="addressbox" v-if="plugins_data.data.showLocatin">
							<i class="icon el-icon-location"></i>
							<div class="text">距离999km</div>
						</div>
					</div>
				</div>
				<div class="goodsbox" v-if="plugins_data.data.storeStyle==1">
					<div class="notgood">
						<el-image class="img" :src="require('assets/images/not-good.png')" fit="cover"></el-image>
						<div class="text">该店铺暂未上传商品</div>
					</div>
				</div>
			</div>
		</template>
		
		<div class="cardbox" :style="{
			'background':plugins_data.style.background,
			'borderRadius':plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_top/2+'px '+plugins_data.style.border_radius_bottom/2+'px '+plugins_data.style.border_radius_bottom/2+'px',
			'margin-bottom':(index==list.length?0:plugins_data.style.margin_bottom/2+'px')
			}"
			v-for="(item,index) in list" :key="index">
			<div class="storebox">
				<el-image
					:class="{'logo':plugins_data.data.storeStyle==1,'logo2':plugins_data.data.storeStyle==2}"
					:style="{'border-radius':plugins_data.data.logoType==1?'50%':'6px'}" :src="item.logo" fit="cover"></el-image>
				<div class="infobox" :style="plugins_data.data.storeStyle==2?'height:60px':''">
					<div class="name">{{item.name}}</div>
					<div class="number" v-if="plugins_data.data.showTotal">在售商品{{item.goods_count}}件</div>
				</div>
				<div class="rightbox">
					<div class="btn" v-if="plugins_data.data.showBtn">进店</div>
					<div class="addressbox" v-if="plugins_data.data.showLocatin">
						<i class="icon el-icon-location"></i>
						<div class="text">距离{{calcDistance(item.distance)}}</div>
					</div>
				</div>
			</div>
			<div class="goodsbox" v-if="plugins_data.data.storeStyle==1">
				<div class="notgood" v-if="item.goods.length==0">
					<el-image class="img" :src="require('assets/images/not-good.png')" fit="cover"></el-image>
					<div class="text">该店铺暂未上传商品</div>
				</div>
				<div class="box" v-for="(goodsitem,i) in item.goods" :key="i">
					<el-image class="img" :src="goodsitem.image" fit="cover"></el-image>
					<div class="price">￥{{goodsitem.erp_goods.is_spec?goodsitem.min_price:goodsitem.erp_goods.retail_price}}</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { eltips,mapkey } from '@/util/util.js';
export default {
	name: 'businessView',
	data() {
		return {
			list:[],
			lng:'',
			lat:'',
		};
	},
	props: {
		plugins_data: { type: Object }
	},
	watch: {
		'plugins_data.data':{
		    deep:true,
		    handler:function(newV,oldV){
				this.getStoreList()
		    }
		},
		'plugins_data.data.category':{
		    deep:true,
		    handler:function(newV,oldV){
				this.getStoreList()
		    }
		}
	},
	created() {
		this.getlocation()
	},
	mounted() {
		this.isLocation()
	},
	methods:{
		isLocation(){
			if(this.lat){
				this.getStoreList()
			}else{
				setTimeout(()=>{
					this.isLocation()
				},1000)
			}
		},
		//通过ip获取用户位置信息
		getlocation() {
			let url = 'https://apis.map.qq.com/ws/location/v1/ip';
			this.$jsonp(url, {key: mapkey,output: 'jsonp',callback:this.text()}).then(res => {
				this.lng = res.result.location.lng
				this.lat = res.result.location.lat
			}).catch(err => {
				console.log(err);
			});
		},
		
		text(){
			console.log('text')
		},
		
		
		calcDistance(distance){
			if(distance>999){
				return parseFloat((distance/1000).toFixed(2))+'km'
			}else if(distance>100){
				return distance+'m'
			}else{
				return '100米以内'
			}
		},
		getStoreList(){
			let data = {
				type:1,
				is_page:0,
				lng:this.lng,
				lat:this.lat,
			}
			let storeType = this.plugins_data.data.storeType
			if(storeType == 1){
				if(this.plugins_data.data.storeList.length==0) return this.list=[];
				let ids = []
				this.plugins_data.data.storeList.forEach(item=>{
					ids.push(item.id)
				})
				data.ids = ids
			}
			
			if(storeType==2){
				if(!this.plugins_data.data.category) return this.list=[];
				data.category_id = this.plugins_data.data.category.id
				data.store_limit = this.plugins_data.data.limit
			}
			
			if(storeType==3){
				data.store_limit = this.plugins_data.data.limit
			}
			this.axios.get('/api/store/store',{params:data}).then(res=>{
				if(res.code==200){
					this.list = res.data
				}else{
					eltips(res.msg)
				}
			})
		},
		getList(data){
			this.axios.get('/api/store/store',{params:data}).then(res=>{
				if(res.code==200){
					this.list = res.data
				}else{
					eltips(res.msg)
				}
			})
		}
	}
};
</script>

<style scoped lang="less">
#businessView{
	.cardbox{
		.storebox{
			display: flex;
			align-items: center;
			padding: 12px;
			.logo2{
				flex: 0 0 60px;
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}
			.logo{
				flex: 0 0 40px;
				width: 40px;
				height: 40px;
				margin-right: 10px;
			}
			.infobox{
				flex: 1;
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				.name{
					font-size: 14px;
					line-height: 24px;
					color: #333333;
				}
				.number{
					color: #999999;
					font-size: 12px;
					line-height: 22px;
				}
			}
			.rightbox{
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				.btn{
					padding: 0 12px;
					font-size: 12px;
					border-radius: 15px;
					border: 1px solid #FF3C29;
					color: #FF3C29;
					display: inline-block;
					border-radius: 4rpx;
					text-align: center;
					margin: 0 0 auto auto;
				}
				.addressbox{
					display: flex;
					align-items: center;
					margin-top: 6px;
					.icon{
						font-size: 14px;
						color:#FF3C29;
					}
					.text{
						line-height:18px;
						font-size:12px;
						color:#999999;
					}
				}
			}
		}
		.goodsbox{
			padding: 0 12px 12px;
			.notgood{
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.img{
					width: 80px;
					height: 70px;
					margin: 8px auto;
				}
				.text{
					color: #969696;
					font-size: 12px;
					line-height: 18px;
				}
			}
			.box{
				border-radius: 6px;
				display: inline-block;
				width: 32%;
				padding-top: 32%;
				margin-right: 2%;
				position: relative;
				background: rgba(244,246,248);
				&:last-child{
					margin-right: 0;
				}
				.img{
					position: absolute;
					left: 0;
					top: 0;
					border-radius: 6px;
					width: 100%;
					height: 100%;
				}
				.price{
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 2;
					background: rgba(0,0,0,0.5);
					color: #FFFFFF;
					border-radius: 0 6px 0 6px;
					line-height: 18px;
					height: 18px;
					padding: 0 6px;
					font-size: 12px;
				}
			}
		}
	}
	
}
</style>
