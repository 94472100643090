<template>
  <div id="chooselink">
    <el-dialog
      title="选择优惠券"
      center
      :visible.sync="chooseShow"
      :modal-append-to-body="false"
      :append-to-body="false"
      :close-on-click-modal="false"
      width="980px"
      :show-close="false"
    >
      <div>
        <div class="contentbox">
          <div class="goodsbox">
            <div class="goodheadbox">
              <el-form :inline="true">
                <el-form-item label="优惠券名称">
                  <el-input
                    v-model="goodkeyword"
                    placeholder="优惠券名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="优惠券类型">
                  <el-select v-model="couponType" placeholder="请选择">
                    <el-option key="0" label="满减券" value="0"></el-option>
                    <el-option key="1" label="折扣券" value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    size="small"
                    @click="goodChangepage(1)"
                    >查询</el-button
                  >
                </el-form-item>
              </el-form>
            </div>

            <div class="dialogtabel">
              <el-table
                v-loading="goodloading"
                :data="couponlist"
                style="width: 100%"
                stripe
                :header-cell-style="{ background: '#fafafa', color: '#999' }"
              >
                <el-table-column label="类型">
                  <template slot-scope="scope">
                    {{ scope.row.type == 1 ? "折扣券" : "满减券" }}
                  </template>
                </el-table-column>
                <el-table-column label="名称">
                  <template slot-scope="scope">
                    <span class="name">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="优惠内容">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">
                      {{ `满${scope.row.full}享${scope.row.discount}折` }}
                    </span>
                    <span v-else>
                      {{ `满${scope.row.full}减${scope.row.discount}` }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="剩余数量" width="80">
                  <template slot-scope="scope">
                    {{ scope.row.total_quantity }}
                  </template>
                </el-table-column>
                <el-table-column label="创建时间">
                  <template slot-scope="scope">
                    {{ scope.row.created_at }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80" align="center">
                  <template slot-scope="scope">
                    <div
                      class="choosebtn choose not-select"
                      @click="delchooseCoupon(scope.row)"
                      v-if="choose_id.indexOf(scope.row.id) != -1"
                    >
                      已选
                    </div>
                    <div
                      class="choosebtn not-select"
                      v-else
                      @click="chooseCoupon(scope.row)"
                    >
                      选择
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div class="pages" v-if="goodtotal > 5">
              <el-pagination
                background
                :hide-on-single-page="true"
                @current-change="goodChangepage"
                :current-page="goodpage"
                :page-size="5"
                layout="total, prev, pager, next, jumper"
                :total="goodtotal"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { eltips } from "@/util/util.js";
import { shoppingLinkArr } from "../../storeplugins/common/shoppinglink.js";
export default {
  name: "chooselink",
  data() {
    return {
      type: 1,
      couponType: "",
      options: [
        { label: "商城页面", value: 1 },
        { label: "商品", value: 2 },
        { label: "直播间选择器", value: 3 },
        { label: "优惠券", value: 4 },
        { label: "自定义页面", value: 5 },
        { label: "商品分类", value: 6 },
        { label: "小程序跳转", value: 7 },
        { label: "商户", value: 8 },
      ],
      //页面选择
      linkList: shoppingLinkArr,
      //商品选择
      goodkeyword: "",
      goodloading: false,
      couponlist: [],
      goodpage: 1,
      goodtotal: 0,
      diyLoading: false,
      diyKeyword: "",
      diyPage: 1,
      diyTotal: 0,
      diyList: [],
      classlist: [],
      choose_item: {
        type: "",
        data: "",
      },
      choose: [],
      choose_id: [],
      setting_level: 1,
    };
  },
  props: {
    chooseShow: {
      type: Boolean,
      default: false,
    },
    coupon: {
      type: Array,
      default:()=>[],
    },
  },
  watch: {
    chooseShow() {
      this.choose_item = {
        type: "",
        data: "",
      };
    },
  },
  created() {
    this.getcouponlist();
    this.coupon.forEach(row=>{
      this.choose_id.push(row.id)
      this.choose.push(row)
    })
  },
  methods: {
    delchooseCoupon(item) {
      this.choose.splice(this.choose_id.indexOf(item.id), 1);
      this.choose_id.splice(this.choose_id.indexOf(item.id), 1);
    },
    chooseCoupon(item) {
      this.choose.push(item);
      this.choose_id.push(item.id);
    },
    confirm() {
      this.$emit("confirm", this.choose);
      this.$emit("update:chooseShow", false);
    },
    //关闭组件
    close() {
      this.$emit("update:chooseShow", false);
    },
    //优惠券分页、搜索
    goodChangepage(e) {
      this.goodpage = e;
      this.getcouponlist();
    },
    //获取优惠券列表
    getcouponlist() {
      let data = {
        page: this.goodpage,
        keyword: this.goodkeyword,
        type: this.couponType,
        limit: 5,
      };
      this.goodloading = true;
      this.axios
        .get("/api/retail/admin/marketing/coupon", { params: data })
        .then((res) => {
          this.goodloading = false;
          if (res.code == 200) {
            this.goodtotal = res.data.total;
            this.couponlist = res.data.data;
          } else {
            eltips(res.msg, "error");
          }
        });
    },
  },
};
</script>

<style>
#chooselink .el-dialog__header {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #e8eaec;
}
#chooselink .el-dialog--center .el-dialog__body {
  padding: 10px 0;
}
#chooselink .el-dialog__footer {
  border-top: 1px solid #e8eaec;
  padding-bottom: 10px;
}
</style>
<style scoped lang="less">
.linkheadbox {
  border-bottom: 1px solid #e8eaec;
  padding: 0 25px;
}
.contentbox {
  max-height: calc(100vh - 160px - 160px - 44px);
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 25px;
}
.pagebox {
  margin-top: 20px;
  .page {
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 20px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .box {
        margin-right: 10px;
        flex: 0 0 120px;
        padding: 10px 0;
        width: 120px;
        border: 1px solid #e9edef;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
      .active {
        background: #2d8cf0;
        color: #ffffff;
        border-color: #2d8cf0;
      }
    }
  }
}

.goodsbox {
  .goodheadbox {
    padding-top: 20px;
  }
  .dialogtabel {
    .icon {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 6px;
    }
    .xu {
      background-color: #e6fff9;
      color: #00c5c5;
    }
    .shi {
      background-color: #f0faff;
      color: #2d8cf0;
    }
    .name {
      width: 394px;
      vertical-align: top;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .choosebtn {
      width: 60px;
      line-height: 32px;
      border-radius: 2px;
      color: #2d8cf0;
      border: 1px solid #c3c3c3;
      cursor: pointer;
      &:hover {
        border-color: #2d8cf0;
      }
    }
    .choose {
      background: #2d8cf0;
      color: #ffffff;
      border-color: #2d8cf0;
    }
  }
}

.pages {
  text-align: center;
  background: #ffffff;
  padding: 10px 0;
  border-top: 1px solid #ededed;
}

.classbox {
  width: 100%;
  background: #f4f6f8;
  border: 1px solid #e9edef;
  margin-top: 10px;
  border-radius: 2px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;

  .headbox {
    display: flex;
    flex: 0 0 400px;
    justify-content: left;
    align-items: center;
    padding: 0 30px;

    .icon-box {
      margin-right: 15px;
      font-size: 22px;
      color: #999;
      cursor: pointer;
      padding: 0 6px;

      &:hover {
        color: #1e92ff;
      }

      .codeimgs {
        width: 14px;
        height: 14px;
        border-left: 1px solid #999;
        border-bottom: 1px solid #999;
        position: relative;
        top: -4px;
      }
    }

    .levels {
      margin-right: 20px;
    }

    .inputbox {
      width: 150px;
      margin-right: 20px;
    }

    .addbtn {
      color: #1e92ff;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .imgsbox {
    flex: 0 0 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    .imgborder {
      width: 60px;
      height: 60px;
      position: relative;

      &:hover {
        .meng {
          opacity: 1;
        }
      }

      .img {
        width: 100%;
        height: 100%;
      }

      .meng {
        cursor: pointer;
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
      }
    }

    .addbox {
      border: 1px dashed #e9edef;
      width: 60px;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #fff;

      .addicon {
        font-size: 28px;
        color: #1989fa;
      }

      .text {
        font-size: 12px;
        color: #999;
        line-height: 12px;
      }
    }
  }

  .timebox {
    flex: 2;
  }

  .statusbox {
    flex: 2;
  }

  .operate {
    flex: 0 0 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .btnsty {
      width: 80px;
      padding: 6px 0;
      border: 1px solid #c3c3c3;
      border-radius: 2px;
      cursor: pointer;
      color: #2d8cf0;
    }
    .active {
      background: #2d8cf0;
      color: #ffffff;
      border-color: #2d8cf0;
    }
  }
}

.childbox {
  margin-top: 0;
  border-top: none;
}
</style>
