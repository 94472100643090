import TIM from 'tim-js-sdk/tim-js-friendship.js'
import TIMUploadPlugin from 'tim-upload-plugin'
import axios from 'axios'
import store from '../store'

export let tim;

export function imInit (appid,status) {
	
	// status 0是要登录 1是正常登录以后
	
	tim = TIM.create({
		SDKAppID: appid
	})

	window.setLogLevel = tim.setLogLevel

	// 无日志级别
	tim.setLogLevel(0)
	tim.registerPlugin({
		'tim-upload-plugin': TIMUploadPlugin
	})

	// 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
	tim.on(TIM.EVENT.SDK_READY, onReadyStateUpdate)
	// SDK NOT READT
	tim.on(TIM.EVENT.SDK_NOT_READY, onReadyStateUpdate)
	
	// 监听消息
	tim.on(TIM.EVENT.MESSAGE_RECEIVED, onReceiveMessage)
	
	if(status==0){
		IMlogin(JSON.parse(sessionStorage.getItem('userinfo')).im_account,sessionStorage.getItem('IMtoken'))
	}
	
	store.commit('ImStatus/changeIM', true)
}

export function IMlogin(userID,userSig) {
	
	tim.login({
	    userID: userID,
	    userSig: userSig
	  })
	  .then((rex) => {
		console.log(rex,'IM登录成功')
		  
	    
	    
	    // eltips(res.msg,'error')
		
		// this.$router.push({path:'/index'});
	  })
	  .catch(error => {
	    
	  })
}



function onReadyStateUpdate({
	name
}) {

	console.log(name, TIM.EVENT.SDK_READY, 'im登录')

	const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false

}

function onReceiveMessage(e){
	
	console.log(e.data[0].payload,'-----触发收到消息')
	
	store.commit('ImStatus/addHistory',e.data[0].payload.text)
}



