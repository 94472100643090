<template>
	<div id="newPeople">
		<div class="newPeoplebox" :style="{
				'paddingLeft':plugins_data.style.box_padding_left/2+'px',
				'paddingRight':plugins_data.style.box_padding_left/2+'px',
				'paddingTop':plugins_data.style.box_padding_top/2+'px',
				'paddingBottom':plugins_data.style.box_padding_bottom/2+'px',
				'marginLeft':plugins_data.style.box_margin_left/2+'px',
				'marginRight':plugins_data.style.box_margin_left/2+'px',
				'marginTop':plugins_data.style.box_margin_top/2+'px',
				'marginBottom':plugins_data.style.box_margin_bottom/2+'px',
				'background':plugins_data.style.box_bg_type===1?'rgba(0,0,0,0)':plugins_data.style.box_background,
				'borderRadius':plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_bottom/2+'px '+plugins_data.style.box_radius_bottom/2+'px',
			}">
			<img class="backgroundImg" :style="{'borderRadius':plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_top/2+'px '+plugins_data.style.box_radius_bottom/2+'px '+plugins_data.style.box_radius_bottom/2+'px',
			}" v-if="plugins_data.backgroundImg" :src="plugins_data.backgroundImg" alt="">
			<div class="newPeopleHeadbox" :style="{'color':plugins_data.style.title_color}">
				<div class="left">
					<!-- {{plugins_data.style.title_text}} -->
				</div>
				<div class="right" v-if="plugins_data.style.title_right">
					<div class="text">查看更多</div>
					<i class="el-icon-arrow-right icon"></i>
				</div>
			</div>
			<div :style="{
				'paddingLeft':plugins_data.style.content_padding_left/2+'px',
				'paddingRight':plugins_data.style.content_padding_left/2+'px',
				'background':plugins_data.style.content_bg_type===1?'rgba(0,0,0,0)':plugins_data.style.content_background,
				'borderRadius':plugins_data.style.content_radius_top/2+'px '+plugins_data.style.content_radius_top/2+'px '+plugins_data.style.content_radius_bottom/2+'px '+plugins_data.style.content_radius_bottom/2+'px',
			}">
				<div class="newPeopleContentbox" :style="{
					'paddingTop':plugins_data.style.content_padding_top/2+'px',
					'paddingBottom':plugins_data.style.content_padding_top/2+'px',
				}">
					<div class="goodsbox" v-if="goods.length==0" :style="{
						'marginRight':plugins_data.style.content_padding_left/2+'px'
					}">
						<el-image class="image" src="" fit="cover"></el-image>
						<div class="name">请选择商品</div>
						<div class="price">￥0.00</div>
					</div>
					<div class="goodsbox" v-for="(item,index) in goods" :key="index" :style="{
						'marginRight':(index+1)<goods.length?plugins_data.style.content_padding_left/2+'px':0,
					}">
						<el-image class="image" :src="item.goods.image" fit="cover"></el-image>
						<div class="name">{{item.goods.name}}</div>
						<div class="price">￥{{item.price}}</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
export default {
	name: 'newPeople',
	data() {
		return {
			goods:[],
		};
	},
	props: {
		plugins_data: { type: Object },
	},
	watch: {
		'plugins_data.activity':{ //监听的对象
		    deep:true, //深度监听设置为 true
		    handler:function(newV,oldV){
				if(newV.id){
					this.getList()
				}else{
					this.goods = []
				}
		    }
		}
	},
	mounted() {
		let activity = this.plugins_data.activity
		if(activity){
			this.getList()
		}
	},
	methods:{
		getList(){
			this.axios.get('/api/retail/admin/seckill/info/'+this.plugins_data.activity.id).then(res=>{
				if(res.code==200){
					this.goods = res.data.goods
				}else{
					eltips(res.msg,'error')
				}
			})
		}
	}
};
</script>



<style scoped lang="less">
#newPeople{
	.newPeoplebox{
	position: relative;
	z-index: 1;
	.backgroundImg{
		position: absolute;
		width: 100%;
		height: 100%!important;
		z-index: -1;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
		.newPeopleHeadbox{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 14px;
			.left{
				font-size: 14px;
				font-weight: bold;
			}
			.right{
				display: flex;
				align-items: center;
				font-size: 12px;
				.icon{
					margin-left: 6px;
				}
			}
		}
		.newPeopleContentbox{
			overflow-y: scroll;
			width: 100%;
			white-space: nowrap;
			.goodsbox{
				display: inline-block;
				vertical-align: top;
				.image{
					width: 86px;
					height: 86px;
					border-radius: 8px;
				}
				.name{
					width: 86px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 13px;
					line-height: 13px;
					margin-top: 6px;
				}
				.price{
					color: #FF0000;
					font-size: 13px;
					line-height: 13px;
					margin-top: 6px;
				}
			}
		}
	}
}
</style>
